import { observer } from "mobx-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { View, Text, ScrollView } from "react-native";
import {
  CurvePanelState,
  CurvesPropertiesPanelModel,
  CurvesSubpanels,
} from "../../../models/CurvesPropertiesPanel/CurvesPropertiesPanel";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { PropertiesList } from "../DefaultPropertiesPanel/PropertiesList";
import { CurvesStack } from "./CurvesStack";

type Props = {
  subpanel: CurvesSubpanels;
  model: CurvesPropertiesPanelModel;
};

export const CurvesPanelBody: FC<Props> = observer((props) => {
  const { subpanel, model } = props;
  const theme = useTheme();
  const r = model.layout.getSubpanelPos(subpanel);
  const st = {
    //borderColor: theme.colors["panel.border"],
    //backgroundColor: theme.colors["panel.background"],
    borderTopWidth: 0,
    backgroundColor: theme.tabsTheme.style.tabBackground,
    ...r.layoutObject,
  };
  if (model.state == CurvePanelState.CURVE) {
    return (
      <View style={{...st, position: 'absolute'}}>
        <CurvesStack subpanel={subpanel} model={model} />
      </View>
    );
  } else if (model.state == CurvePanelState.JSON) {
    return (
      <ScrollView style={{ ...st }}>
        <Text style={{ color: "white" }}>
          {JSON.stringify(model.node.model.json, null, 2)}
        </Text>
      </ScrollView>
    );
  } else {
    return (
      <View style={st}>
        <PropertiesList node={model.node} />
      </View>
    );
  }
});
