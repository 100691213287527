import { makeAutoObservable, observable } from "mobx";
import DARK from "./dark";
import LIGHT from "./quietlight-color-theme";
import { Theme } from "./Theme";

export class Themes {
  themes = observable.map({});
  currentThemeName = "";

  constructor() {
    this.addTheme(new Theme("Dark", true, DARK.colors));
    this.addTheme(new Theme("Light", false, LIGHT.colors));
    this.currentThemeName = "Dark";
    makeAutoObservable(this);
    this.readCurrentSchemeName();
  }

  addTheme(theme: Theme) {
    this.themes.set(theme.name, theme);
    theme.setThemes(this);
  }

  setTheme(name: string) {
    this.currentThemeName = name;
  }

  readCurrentSchemeName() {
    //TODO
    this.currentThemeName = "Dark";
  }

  get currentTheme(): Theme {
    let t = this.themes.get(this.currentThemeName);
    if (!t) {
      t = Array.from(this.themes.values())[0];
    }
    return t;
  }
}

export const themes = new Themes();
