import { uniqueId } from "lodash";
import { makeAutoObservable } from "mobx";

export class StillImage {
  key = uniqueId("image");
  name = "unnamed"
  image;

  constructor(image: HTMLImageElement, name: string) {
    this.image = image;
    this.name = name;
    makeAutoObservable(this);
  }
}
