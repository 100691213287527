import {
  MenuOptionCustomStyle,
  MenuOptionsCustomStyle,
} from "react-native-popup-menu";
import { Theme } from "./Theme";

export function createMenuTheme(theme: Theme) {
  const triggerStyles = {
    triggerText: {
      color: theme.colors["editor.foreground"],
    },
    /*triggerOuterWrapper: {
      backgroundColor: "orange",
      padding: 5,
      flex: 1,
    },
    triggerWrapper: {
      backgroundColor: "blue",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    triggerTouchable: {
      underlayColor: "darkblue",
      activeOpacity: 70,
      style: {
        flex: 1,
      },
    },*/
  };
  const optionsStyle: MenuOptionsCustomStyle = {
    optionsContainer: {
      backgroundColor: theme.colors["menu.background"],
    },
  };
  const optionStyle: MenuOptionCustomStyle = {
    optionTouchable: {},
    optionText: {
      color: theme.colors["menu.foreground"],
    },
  };
  return { triggerStyles, optionStyle, optionsStyle };
}
