import React, { ReactChildren, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  StyleSheet,
  LayoutChangeEvent,
  View,
  ViewStyle,
  ScrollView,
} from "react-native";
import { LinearNode } from "./LinearNode";
import { ILinearNodes } from "../models/LinearNodesModel";
import Svg, { Line, Path, Polygon } from "react-native-svg";
import { defer } from "lodash";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import {
  Menu,
  MenuTrigger,
  MenuOption,
  MenuOptions,
  MenuOptionCustomStyle,
  MenuOptionsCustomStyle,
} from "react-native-popup-menu";
import { ILinearNode } from "../models/LinearNodeModel";
import { createMenuTheme } from "../../../utils/theme/menuTheme";

type Props = {
  style: ViewStyle;
  data: ILinearNodes;
  renderNodeContent?(node: ILinearNode): JSX.Element;
  customRenderer?(node: ILinearNode): boolean;
};

export const LinearNodes = observer((props: Props) => {
  const { data, style, renderNodeContent, customRenderer } = props;
  const theme = useTheme();
  const st = {
    borderColor: theme.colors["panel.border"],
    backgroundColor: theme.colors["panel.background"],
  };
  const [actualSize, setActualSize] = useState({
    width: data.shared.allWidth,
    height: data.shared.height,
  });
  useEffect(() => {
    const aw = Math.max(data.shared.allWidth - 10, data.shared.width);
    if (aw !== actualSize.width || data.shared.height != actualSize.height) {
      setActualSize({ width: aw, height: data.shared.height });
    }
  }, [data.shared.allWidth, data.shared.width, data.shared.height]);
  const renderNodes = () => (
    <>
      <LinearNode
        customRenderer={customRenderer}
        renderNodeContent={renderNodeContent}
        node={data.shared.startNode}
      />
      <LinearNode
        customRenderer={customRenderer}
        renderNodeContent={renderNodeContent}
        node={data.shared.finishNode}
      />
      {data.shared.nodes.map((n) => (
        <LinearNode
          customRenderer={customRenderer}
          renderNodeContent={renderNodeContent}
          node={n}
          key={n.shared.id}
        />
      ))}
    </>
  );

  const renderMenu = () => {
    const s = createMenuTheme(theme);
    return (
      <Menu style={{ position: "absolute", left: 5, top: 5 }}>
        <MenuTrigger customStyles={s.triggerStyles} text={` ⋮ `}></MenuTrigger>
        <MenuOptions customStyles={s.optionsStyle}>
          <MenuOption
            customStyles={s.optionStyle}
            onSelect={() => {}}
            text={"Unhighlight"}
          />
          <MenuOption
            customStyles={s.optionStyle}
            onSelect={() => {}}
            text="Delete"
          />
        </MenuOptions>
      </Menu>
    );
  };

  const renderSvg = () => {
    return (
      <Svg
        style={{ width: data.shared.allWidth, height: data.shared.height }}
        viewBox={`0 0 ${data.shared.allWidth} ${data.shared.height}`}
      >
        {data.shared.animationStartedAt !== null ||
          data.shared.arrows.map((arrow) => {
            const [sx, sy, cx, cy, ex, ey, ae, as, ec] = arrow.points;
            const endAngleAsDegrees = ae * (180 / Math.PI);
            return (
              <React.Fragment key={arrow.id}>
                <Path
                  d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`}
                  stroke={theme.colors["charts.lines"]}
                  fill="none"
                />
                <Polygon
                  stroke={theme.colors["charts.lines"]}
                  points="0,-6 12,0, 0,6"
                  transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
                />
              </React.Fragment>
            );
          })}
        {data.shared.draggingOverX !== null && (
          <Line
            x1={data.shared.draggingOverX}
            x2={data.shared.draggingOverX}
            y1={0}
            y2={data.shared.height}
            stroke={theme.colors["charts.red"]}
          />
        )}
      </Svg>
    );
  };
  return (
    <View
      style={[st, style]}
      onLayout={(evt) =>
        defer(() => {
          data.shared.setLayout(
            evt.nativeEvent.layout.width,
            evt.nativeEvent.layout.height
          );
        })
      }
    >
      <ScrollView horizontal={true} style={[{}, StyleSheet.absoluteFill]}>
        <View
          onLayout={(evt: LayoutChangeEvent) =>
            setActualSize({
              width: evt.nativeEvent.layout.width,
              height: evt.nativeEvent.layout.height,
            })
          }
          // @ts-ignore
          //onMouseLeave={() => console.info("mouseLeave")}
          //onMouseEnter={() => console.info("mouseEnter")}
          //onMouseMove={(evt: any) =>
          //console.info(
          //</ScrollView>    `mouseMove ${evt.nativeEvent.clientX} ${evt.nativeEvent.clientY}`
          //)
          //}
          style={{
            ...StyleSheet.absoluteFillObject,
            minWidth: data.shared.allWidth,
            height: data.shared.height,
            overflow: "hidden",
          }}
        >
          {renderSvg()}
          {renderNodes()}
        </View>
      </ScrollView>
      {renderMenu()}
    </View>
  );
});
