import { flow, makeAutoObservable } from "mobx";
import { LutCreator } from "../LutCreator";
import { IBackendApi } from "./IBackendApi";
import { io } from "socket.io-client";
import { getSnapshot } from "mobx-state-tree";

localStorage.debug = "*";

const isProduction = window.location.host.indexOf("localhost") < 0;

const [webApi, socketApi] = isProduction
  ? ["https://lutapi.do.yatsyk.com", "wss://lutapi.do.yatsyk.com"]
  : ["http://localhost:4555", "ws://localhost:4555"];

export class SocketBackedApi implements IBackendApi {
  _lutCreator: LutCreator | null = null;
  socket;
  host;
  constructor() {
    this.host = webApi;
    //this.lutCreator = lutCreator;
    this.socket = io(/*this.host*/ socketApi);
    makeAutoObservable(this, { socket: false });
    //this.create();
  }

  setLutCreator(lutCreator: LutCreator) {
    this._lutCreator = lutCreator;
  }

  get lutCreator(): LutCreator {
    if (this._lutCreator) {
      return this._lutCreator;
    } else {
      throw "set lut creator";
    }
  }

  create = flow(function* (this: SocketBackedApi) {
    this.setListeners();
    this.socket.emit("createEngine");
  });

  loadLut = flow(function* (
    this: SocketBackedApi,
    lutCanvas: HTMLCanvasElement | null
  ) {
    try {
      new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = `${this.host}/lut.png?ui=${this.lutCreator.engine.updateIndex}`;
        image.onerror = (err) => {
          reject(err);
        }
        image.onload = () => {
          this.lutCreator.setLutCanvas(
            image,
            this.lutCreator.engine.updateIndex
          );
          resolve(null);
        };
      });
    } catch (ee) {
      console.error(ee);
      throw ee;
    }
  });

  setNumberValue(nodeIndex: number, propertyId: number, value: number) {
    this.socket.emit("setNumberValue", { nodeIndex, propertyId, value });
  }

  setIntValue(nodeIndex: number, propertyId: number, value: number) {
    this.socket.emit("setIntValue", { nodeIndex, propertyId, value });
  }

  setCurvePoints(
    nodeIndex: number,
    propertyId: number,
    points: Array<PointArrayNotation>
  ) {
    this.socket.emit("setCurve", { nodeIndex, propertyId, points });
  }
  updateCurveDotPos(
    nodeIndex: number,
    propertyId: number,
    dotIndex: number,
    x: number,
    y: number
  ) {
    this.socket.emit("updateCurveDotPos", {
      nodeIndex,
      propertyId,
      dotIndex,
      x,
      y,
    });
  }

  setListeners() {
    this.socket.on("modelSnapshot", (msg) => {
      console.info("modelSnaphot", msg);
      this.lutCreator.applySnapshot(msg);
    });

    this.socket.on("modelPatches", (msg) => {
      console.info("modelPatches", msg);
      this.lutCreator.applyPatches(msg);
    });
  }
}
