import { observer } from "mobx-react";
import React from "react";
import { View, Text } from "react-native";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { LutCreator } from "../../models/LutCreator";
import { PreviewState } from "../../models/LutPreview/LutPreview";
import { useTheme } from "../../utils/theme/ThemesProvider";
import { createMenuTheme } from "../../utils/theme/menuTheme";

type Props = { lutCreator: LutCreator };

export const PreviewToolbar = observer((props: Props) => {
  const {
    lutCreator,
    lutCreator: { preview },
  } = props;
  const rect = lutCreator.layout.previewToolbarRect;
  const theme = useTheme();

  const renderMenu = () => {
    const s = createMenuTheme(theme);
    return (
      <Menu style={{ margin: 8, maxWidth: 200 }}>
        <MenuTrigger
          customStyles={s.triggerStyles}
          text={preview.state}
        ></MenuTrigger>
        <MenuOptions customStyles={s.optionsStyle}>
          {[PreviewState.Picture, PreviewState.DocumentJsonTree].map((k) => {
            return (
              <MenuOption
                key={k}
                customStyles={s.optionStyle}
                onSelect={() => {
                  preview.setState(k);
                }}
                text={k}
              />
            );
          })}
        </MenuOptions>
      </Menu>
    );
  };

  return (
    <View
      style={{
        ...rect.layoutObject,
        borderColor: theme.colors["panel.border"],
        backgroundColor: theme.colors["panel.background"],
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
      }}
    >
      {renderMenu()}
    </View>
  );
});
