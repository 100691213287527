import { makeAutoObservable } from "mobx";
import { PanResponderGestureState } from "react-native";
import { DisplacementGrid } from "./DisplacementGrid";
import { DisplacementGridDot } from "./DisplacementGridDot";

export class DisplacementGridMouseHelper {
  gestureState: PanResponderGestureState | null = null;
  pressedDot: DisplacementGridDot | null = null;
  originalPosition: [number, number] = [0, 0];
  solverAlpha = 1.0;
  moved = false;
  grid: DisplacementGrid;

  constructor(grid: DisplacementGrid) {
    this.grid = grid;
    makeAutoObservable(this);
  }

  click(dot: DisplacementGridDot) {
    console.info("click");
  }

  finishDragging(dot: DisplacementGridDot) {
    this.pressedDot = null;
    this.originalPosition = [0, 0];
    if (!this.moved) {
      this.click(dot);
    }
  }

  move(dot: DisplacementGridDot, gestureState: PanResponderGestureState) {
    this.gestureState = gestureState;
    /*if (!this.moved) {
      if (
        gestureState.dx * gestureState.dx + gestureState.dy * gestureState.dy >
        8
      ) {
        this.moved = true;
      } else {
        return;
      }
    }
    const shiftX = gestureState.dx * this.grid.coordinates.svgToModelMatrix.a;
    const shiftY = gestureState.dy * this.grid.coordinates.svgToModelMatrix.d;
    dot.setX(this.originalPosition[0] + shiftX);
    dot.setY(this.originalPosition[1] + shiftY);
    const p: Array<[number, number]> = [];
    const q: Array<[number, number]> = [];
    const dots: Array<DisplacementDot> = [];
    this.grid.dots!.dots.forEach((d) => {
      if (d.pinned || d == dot) {
        p.push([d.originalX, d.originalY]);
        q.push([d.x, d.y]);
      } else {
        dots.push(d);
      }
    });
    for (const d of dots) {
      const pp: [number, number] = [d.originalX, d.originalY];
      const solver = new IMPMLSSolver(
        pp,
        p,
        q,
        p.length,
        this.currentSolver,
        this.solverAlpha
      );
      const cc = solver.value(pp);
      d.setX(cc[0]);
      d.setY(cc[1]);
    }*/
  }

  startDragging(dot: DisplacementGridDot) {
    this.pressedDot = dot;
    this.originalPosition = [this.pressedDot.x, this.pressedDot.y];
    this.moved = false;
  }

  /*setCurrentSolver(kind: MLSSolverKind) {
    this.currentSolver = kind;
  }*/
}
