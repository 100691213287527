import { observer } from "mobx-react";
import React, { FC } from "react";
import { View } from "react-native";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { Rect } from "../../../utils/Rect";
import { PanelWithTitle } from "../PanelWithTitle/PanelWithTitle";
import { PropertiesList } from "./PropertiesList";

type Props = {
  node: DefaultLutNode;
};

export const DefaultPropertiesPanel:FC<Props> = observer((props: Props) => {
  const { node } = props;
  return (
    <PanelWithTitle
      node={node}
      renderBody={function (where: Rect) {
        return <PropertiesList node={node} />;
      }}
      renderToolbar={function (where: Rect) {
        return <View />;
      }}
      menuItems={undefined}
    />
  );
});
