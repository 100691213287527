import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ErrorBoundary from "react-native-error-boundary";
import { StoreRoot } from "../../models/StoreRoot";
import { PreviewPanel } from "../PreviewPanel/PreviewPanel";
import { useTheme } from "../../utils/theme/ThemesProvider";
import { MainToolbar } from "../MainToolbar/MainToolbar";
import { IBackendApi } from "../../models/api/IBackendApi";
import { NodesPanel } from "../NodesPanel/NodesPanel";
import { PreviewToolbar } from "../PreviewToolbar/PreviewToolbar";
import { Footer } from "../Footer/Footer";
import { PropertiesPanelRoot } from "../PropertiesPanel/PropertiesPanelRoot/PropertiesPanelRoot";

type Props = { createApi: () => IBackendApi };

const ApplicationRoot = observer((props: Props) => {
  const [store, setStore] = useState<StoreRoot | null>(null);
  const { createApi } = props;
  const theme = useTheme();
  useEffect(() => {
    const s = new StoreRoot(createApi);
    s.lutCreator.create();
    setStore(s);
  }, []);
  if (store) {
    return (
      <ErrorBoundary>
        <style>
          {`
:root {
  color-scheme: ${theme.isDark ? "dark" : "light"};
}
        `}
        </style>
        <View
          style={{
            backgroundColor: theme.mainBackground,
            borderWidth: 0,
            width: "100%",
            height: "100%",
          }}
          onLayout={(evt: LayoutChangeEvent) =>
            store.setLayout(
              evt.nativeEvent.layout.width,
              evt.nativeEvent.layout.height
            )
          }
        >
          <NodesPanel lutCreator={store.lutCreator} />
          <MainToolbar lutCreator={store.lutCreator} />
          <PreviewToolbar lutCreator={store.lutCreator} />
          <PreviewPanel lutCreator={store.lutCreator} />
          {store.lutCreator.selectedNode && (
            <PropertiesPanelRoot node={store.lutCreator.selectedNode} />
          )}
          <Footer lutCreator={store.lutCreator} />
        </View>
      </ErrorBoundary>
    );
  } else {
    return <View />;
  }
});

export default ApplicationRoot;
