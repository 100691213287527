import { defer } from "lodash";
import { makeAutoObservable, flow } from "mobx";
import { LutPreview } from "./LutPreview";

export function loadImage(path: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const i = new Image();
    i.src = path;
    //console.info("path=", path);
    //i.crossOrigin = "anonymous";
    i.onload = () => resolve(i);
    i.onerror = (err) => reject(err);
  });
}

export class SampleImage {
  preview;
  path;
  image?: HTMLImageElement = undefined;

  constructor(preview: LutPreview, path: string) {
    this.preview = preview;
    this.path = path;
    makeAutoObservable(this);
    if (typeof window !== "undefined") {
      defer(() => this.loadImage());
    }
  }

  get key() {
    return this.path;
  }

  loadImage = flow(function* (this: SampleImage) {
    //console.info("loadImage start");
    try {
      this.image = yield loadImage(this.path);
      console.info("image loaded", this.image?.width);
    } catch (ee) {
      console.error(ee);
    }
  });
}
