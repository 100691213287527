import { range } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import Svg, { Circle, G, Line, Path, Rect as SvgRect } from "react-native-svg";
import { applyToPoint, toSVG } from "transformation-matrix";
import { CurveModel } from "../../../models/CurvesPropertiesPanel/CurveModel";
import { Rect } from "../../../utils/Rect";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { CurveDot } from "./CurveDot";

type Props = {
  style?: any;
  model: CurveModel;
};

export const CurvesControl = observer((props: Props) => {
  const { style, model } = props;
  const {
    tabsTheme: { style: ts },
  } = useTheme();
  const ref = useRef<View | null>(null);
  if (model) {
    const ap = (p: PointArrayNotation) => applyToPoint(model.matrixDocToSvg, p);
    const defaultStyle = { width: model.width, height: model.height };

    const renderCurveBackground = (r: Rect) => {
      return (
        <G>
          <SvgRect
            x={r.x}
            y={r.y}
            width={r.width}
            height={r.height}
            fill={ts.inactiveTabBackground}
          />
          <Line stroke={ts.borders} y1={r.y1} y2={r.y2} x1={r.x1} x2={r.x2} />
          {range(0, 5).map((xi) => {
            const x = r.x + (r.width / 4) * xi;
            return (
              <Line
                stroke={ts.borders}
                key={`x:${xi}`}
                y1={r.y1}
                y2={r.y2}
                x1={x}
                x2={x}
              />
            );
          })}
          {range(0, 5).map((yi) => {
            const y = r.y + (r.height / 4) * yi;
            return (
              <Line
                stroke={ts.borders}
                key={`y:${yi}`}
                y1={y}
                y2={y}
                x1={r.x1}
                x2={r.x2}
              />
            );
          })}
        </G>
      );
    };

    return (
      <View
        style={{
          position: "absolute",
          height: model.height + 30,
        }}
      >
        <View
          ref={ref}
          style={{
            //borderWidth: 1,
            //borderColor: "silver",
            borderStyle: "solid",
            //margin: 30,
            minHeight: 100,
            minWidth: 100,
            //overflow: "hidden",
            ...model.curveStack.rect.layoutObject,
            left: 0,
            top: 0,
            ...style,
          }}
          {...(model.isSelected && model.panResponder.panHandlers)}
          //@ts-ignore
          onMouseMove={(evt) => {
            if (!model.isSelected) {
              return;
            }
            ref.current!.measure((fx, fy, width, height, px, py) => {
              /*console.info(
                `pxpy=${px},${py} model.xy=${model.x},${model.y} client.XY=${evt.clientX},${evt.clientY}`,
                evt
              );*/
              const view: PointArrayNotation = [
                evt.clientX - px,
                evt.clientY - py,
              ];
              const svg = applyToPoint(model.rotateSvg, view);
              const inDoc = applyToPoint(model.matrixSvgToDoc, svg);
              model.setOverPosition(inDoc);
            });
            //var rect = evt.target.getBoundingClientRect();
          }}
          onMouseLeave={() => model.setOverPosition(null)}
        >
          <Svg
            style={{
              borderWidth: 1,
              //borderColor: "white",
              //borderStyle: "solid",
              width: model.width,
              height: model.height,
            }}
            viewBox={`0 0 ${model.width} ${model.height}`}
          >
            <G transform={toSVG(model.rotateSvg)}>
              {renderCurveBackground(
                new Rect(
                  model.padding,
                  model.padding,
                  model.width - 2 * model.padding,
                  model.height - 2 * model.padding
                )
              )}
              <Path
                fill={"none"}
                stroke={model.info.color}
                d={model.approximationPath}
              />
              <Path
                fill={"none"}
                strokeDasharray={4}
                strokeWidth={4}
                stroke={model.info.color}
                d={model.approximationPathCS}
              />
              {(model.dots || []).map((p, i) => (
                <CurveDot model={model} dot={p} />
              ))}
              {model.overPositionOnSvg && (
                <Circle
                  r={4}
                  cx={model.overPositionOnSvg[0]}
                  cy={model.overPositionOnSvg[1]}
                  fill={"blue"}
                />
              )}
            </G>
          </Svg>
        </View>
      </View>
    );
  } else {
    return <View></View>;
  }
});
