import { makeAutoObservable } from "mobx";
import { Rect } from "../../../utils/Rect";
import { DisplacementGridDots } from "./DisplacementGridDots";
import { DisplacementGridMouseHelper } from "./DisplacementGridMouseHelper";
import { GridCoordinates } from "./GridCoordinates";

export class DisplacementGrid {
  controlWidth = 100;
  controlHeight = 100;
  toolbarHeight = 42;
  coordinates = new GridCoordinates();
  dots: DisplacementGridDots = new DisplacementGridDots(this);
  mouseHelper = new DisplacementGridMouseHelper(this);

  constructor() {
    makeAutoObservable(this);
  }

  setLayout(width: number, height: number) {
    this.controlWidth = width || 1;
    this.controlHeight = height || 1;
    this.coordinates.setCanvasPosition(
      new Rect(0, 0, width, height - this.toolbarHeight)
    );
  }
}
