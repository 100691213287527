import { observer } from "mobx-react";
import React from "react";
import { View, Text } from "react-native";
import { LutCreator } from "../../models/LutCreator";
import { LutCreatorAppNewModel } from "../../models/LutCreatorNew/LutCreatorNewAppModel";
import { useTheme } from "../../utils/theme/ThemesProvider";
import { Button } from "../shared_ui/Button";

type Props = { lutCreator: LutCreator | LutCreatorAppNewModel };

export const Footer = observer((props: Props) => {
  const { lutCreator } = props;
  const rect = lutCreator.layout.footerRect;
  const theme = useTheme();

  return (
    <View
      style={{
        ...rect.layoutObject,
        ...rect.layoutObject,
        display: "flex",
        flexDirection: "row",
        borderColor: theme.colors["panel.border"],
        alignItems: "center",
        backgroundColor: theme.colors["panel.background"],
      }}
    >
    </View>
  );
});
