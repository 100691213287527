import { StatusBar } from "expo-status-bar";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Constants from 'expo-constants';

import { MenuProvider } from "react-native-popup-menu";
import ApplicationRoot from "../../components/ApplicationRoot/ApplicationRoot";
import { SocketBackedApi } from "../../models/api/SocketBackendApi";
import { registerRootComponent } from "expo";

export default function WebApplication() {
  console.info("extra:", Constants.manifest?.extra)
  return (
    <MenuProvider>
      <SafeAreaProvider>
        <ApplicationRoot createApi={() => new SocketBackedApi()} />
        <StatusBar />
      </SafeAreaProvider>
    </MenuProvider>
  );
}

registerRootComponent(WebApplication);