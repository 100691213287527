import { makeAutoObservable } from "mobx";
import { Rect } from "../../utils/Rect";
import { themes } from "../../utils/theme/Themes";
import {
  CurvesPropertiesPanelModel,
  CurvesSubpanels,
} from "./CurvesPropertiesPanel";

export class CurvesPropertiesPanelLayout {
  panel;
  x = 0;
  y = 0;
  //width = 100;
  //height = 10
  constructor(panel: CurvesPropertiesPanelModel) {
    this.panel = panel;
    makeAutoObservable(this);
  }

  get width() {
    return this.panel.lutCreator.layout.nodePropertiesRect.width;
  }
  get height() {
    return this.panel.lutCreator.layout.nodePropertiesRect.height;
  }

  //setPosition(x: number, y: number, w: number, h: number) {
  /*setPosition({ x, y, width, height }: LayoutRectangle) { this.x = x; this.y = y; this.width = width;
    this.height = height;
  }*/

  getSubpanelPos(n: CurvesSubpanels): Rect {
    return new Rect({
      y: this.y,
      x: this.x + 2,
      width: this.width - 4,
      height: this.height - this.bottomPanelHeight - 4,
    });
  }

  getSubpanelToolbarPos(n: CurvesSubpanels) {
    let res = { ...this.getSubpanelPos(n).obj };
    res.height = this.toolbarHeight + 2;
    return new Rect(res);
  }

  getSubpanelCurvePos(n: CurvesSubpanels) {
    //let res = { ...this.getSubpanelPos(n).obj };
    //res.y += this.toolbarHeight-2;
    //res.height -= this.toolbarHeight;
    //return new Rect(res);
    const subpanel = this.getSubpanelPos(n).obj;
    const padding = 10;
    const sz = Math.max(
      100,
      Math.min(
        subpanel.width - 2 * padding,
        subpanel.height - 2 * padding - this.toolbarHeight
      )
    );
    const rect = new Rect([
      padding + (subpanel.width - sz) / 2,
      padding + (subpanel.height - sz) / 2,
      sz,
      sz,
    ]);
    return rect;
  }

  get toolbarHeight() {
    const t = themes.currentTheme;
    return t.tabsTheme.style.tabHeight;
  }

  get bottomPanelHeight() {
    //return 3 * this.toolbarHeight;
    return 0;
  }
}
