import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import React from "react";
import { View, Text } from "react-native";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { useTheme } from "../../../utils/theme/ThemesProvider";

type Props = { p: any; node: DefaultLutNode };

export const GridPropertyComponent = observer((props: Props) => {
  const { p } = props;
  const { node } = props;
  const r = node.lutCreator.layout.nodePropertiesRect;
  const theme = useTheme();
  return (
    <View>
      <Text
        style={{
          fontSize: theme.fontSizes[3],
          fontFamily: "mono",
          color: theme.colors["editor.foreground"],
        }}
      >
        Grid {JSON.stringify(getSnapshot(p))}
      </Text>
    </View>
  );
});
