import { makeAutoObservable } from "mobx";
import { DisplacementGrid } from "../../modules/DisplaycementGrid/model/DisplacementGrid";
import { DefaultLutNode } from "../DefaultLutNode";

export class GridPropertiesPanelModel {
  node
  grid = new DisplacementGrid();
  constructor(node: DefaultLutNode) {
    this.node = node;
    makeAutoObservable(this);
  }

  get lutCreator() {
    return this.node.lutCreator;
  }
}
