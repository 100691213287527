import { observer } from "mobx-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { View, Text, ScrollView } from "react-native";
import { GridPropertiesPanelModel } from "../../../models/GridPropertiesPanel/GridPropertiesPanel";
import { DisplacementGridComponent } from "../../../modules/DisplaycementGrid/components/DisplacementGridComponent";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { PropertiesList } from "../DefaultPropertiesPanel/PropertiesList";

type Props = {
  model: GridPropertiesPanelModel;
};

export const GridPanelBody: FC<Props> = observer((props) => {
  const { model } = props;
  const theme = useTheme();
  const st = {
    //borderColor: theme.colors["panel.border"],
    //backgroundColor: theme.colors["panel.background"],
    borderTopWidth: 0,
    backgroundColor: theme.tabsTheme.style.tabBackground,
  };
  return <DisplacementGridComponent grid={model.grid}/>
});
