import { observer } from "mobx-react";
import React, { FC,  } from "react";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { Rect } from "../../../utils/Rect";
import { useTheme } from "../../../utils/theme/ThemesProvider";

type Props = {
  renderBody: (where: Rect) => any;
  node: DefaultLutNode;
};

export const PanelBody: FC<Props> = observer((props) => {
  const { node, renderBody } = props;
  const r = node.lutCreator.layout.nodePropertiesPanelBodyOnPanel;
  const theme = useTheme();
  const st = {
    borderTopWidth: 0,
    backgroundColor: theme.tabsTheme.style.tabBackground,
    ...r.layoutObject,
  };
  return renderBody(r)
});
