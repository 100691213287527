import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import {
  CurvePanelState,
  CurvesPropertiesPanelModel,
  CurvesSubpanels,
} from "../../../models/CurvesPropertiesPanel/CurvesPropertiesPanel";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { Tab, TabPanel } from "../../shared_ui/ToolbarTab";

type Props = {
  model: CurvesPropertiesPanelModel;
};

export const CurvesToolbarTabs: FC<Props> = observer((props) => {
  const { model } = props;
  const theme = useTheme();
  const {
    tabsTheme: { style: ts },
  } = theme;
  const stack = model.rightStack;
  const renderTab = (index: number, width: number) => {
    const info = stack.infos[index];
    const node = stack.curveModels[index];
    return (
      <Tab
        key={`${info.id}`}
        enabled={node.isEnabled}
        label={info.title}
        id={`${info.id}`}
        selected={stack.selectedCurveIndex === index}
        hasCheckbox={node.enabledProperty}
        setEnabled={(v: boolean) => node.setEnabled(v)}
        setSelected={() => stack.setSelectedCurveIndex(index)}
        width={width}
      />
    );
  };
  return <TabPanel tabCount={stack.infos.length} renderTab={renderTab} />;
});
