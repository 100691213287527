import { makeAutoObservable } from "mobx";
import { DisplacementGrid } from "./DisplacementGrid";
import { DisplacementGridDot } from "./DisplacementGridDot";

export class DisplacementGridDots {
  rows = 10;
  cols = 10;
  _dots: Array<DisplacementGridDot> = [];
  circular = false;
  minX = 0;
  minY = 0;
  maxX = 100;
  maxY = 100;
  grid;

  constructor(grid:DisplacementGrid) {
    this.grid = grid;
    makeAutoObservable(this);
    this.layoutUniformly();
  }

  layoutUniformly() {
    const res = [];
    for (let iy = 0; iy < this.rows; iy++) {
      for (let ix = 0; ix < this.cols; ix++) {
        const x =
          this.rows > 1
            ? this.minX + (ix * (this.maxX - this.minX)) / (this.cols - 1)
            : (this.maxX + this.minX) / 2;
        const y =
          this.cols > 1
            ? this.minY + (iy * (this.maxY - this.minY)) / (this.rows - 1)
            : (this.maxY + this.minY) / 2;

        const dot = new DisplacementGridDot(this, x, y);
        res.push(dot);
      }
    }
  }
}
