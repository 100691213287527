import { makeAutoObservable } from "mobx";
import { Theme } from "./Theme";

const photoshopStyle = {
  inactiveTabBackground: "#424242",
  tabBackground: "#535353",
  inactiveText: "#b0b0b0",
  activeText: "#f3f3f3",
  normalTextColor: "#dddddd",
  borders: "#383838",

  textMargin: 4,
  tabHeight: 32,
  tabFontHeight: 18,
  checkboxWidth: 14,
  tabPadding: 10,
};

export class TabsTheme {
  theme;
  constructor(theme: Theme) {
    this.theme = theme;
    makeAutoObservable(this);
  }

  get style() {
    return photoshopStyle;
  }
}
