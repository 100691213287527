import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { ILinearNode } from "../../modules/LinearNodes/models/LinearNodeModel";
import { useTheme } from "../../utils/theme/ThemesProvider";
import { DefaultLutNode } from "../../models/DefaultLutNode";
import { AnyProperty, IntProperty } from "../../LutEngineModel/LutEngineModel";
import { Instance } from "mobx-state-tree";
import Checkbox from "expo-checkbox";

type Props = {
  node: ILinearNode;
};

export const LutNodeContent = observer((props: Props) => {
  const { node } = props;
  const theme = useTheme();
  let enabledProperty: Instance<typeof IntProperty> | null = null;
  if (node instanceof DefaultLutNode) {
    enabledProperty = node.model.properties.find(
      (p: Instance<typeof AnyProperty>) => p.json.title === "Enabled"
    );
  }

  return (
    <View
      style={{
        position: "relative",
        //borderWidth: 1,
        //borderColor: "red",
        width: node.shared.width - 4,
        height: node.shared.height - 4,
      }}
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          //borderWidth: 1,
          //borderColor: "red",
          width: node.shared.width - 4,
          height: node.shared.height - 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            fontSize: theme.fontSizes[3],
            color: node.shared.movable
              ? theme.colors["editor.foreground"]
              : theme.colors["editorGhostText.foreground"],
          }}
        >
          {node.shared.name}
        </Text>
      </View>
      {enabledProperty && (
        <Checkbox
          style={{ position: "absolute", left: 2, top: 2 }}
          value={!!enabledProperty.value}
          onValueChange={(v: boolean) => {
            console.info("onValueChange", v);
            (node as DefaultLutNode).lutCreator.setIntValue(
              enabledProperty!.nodeIndex,
              enabledProperty!.propertyId,
              v ? 1 : 0
            );
          }}
        />
      )}
    </View>
  );
});
