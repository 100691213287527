import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { ViewStyle } from "react-native";
import uuid from "react-native-uuid";
import { DefaultLinearNode, ILinearNode } from "./LinearNodeModel";
import { getBoxToBoxArrow } from "perfect-arrows";
import { now } from "mobx-utils";

export class LinearNodesShared {
  width = 100;
  height = 100;
  animationDuration = 300;
  owner;
  _nodes: IObservableArray<ILinearNode> = observable.array();
  animationStartedAt: null | number = null;

  constructor(owner: ILinearNodes) {
    this.owner = owner;
    makeAutoObservable(this);
  }

  get nodes() {
    if (this.owner.nodes) {
      return this.owner.nodes;
    } else {
      return this._nodes;
    }
  }

  get startNode() {
    const node = new DefaultLinearNode(this.owner);
    node.shared.setMovable(false);
    node.shared.setName("Start");
    node.shared.setX(this.spacing / 2);
    return node;
  }

  get nodeWidth() {
    return this.nodeHeight * 1.3;
  }

  get nodeHeight() {
    return this.height - 50;
  }

  get spacing() {
    return this.nodeHeight / 1.5;
  }
  get draggingNodes() {
    return this.nodes.filter((n) => n.shared.dragging);
  }

  get selectedNode() {
    return this.nodes.find((v) => v.shared.selected);
  }

  get finishNode() {
    const node = new DefaultLinearNode(this.owner);
    node.shared.setMovable(false);
    node.shared.setName("Finish");
    node.shared.setX(
      this.startMovableNodes + this.internalNodesWidth + this.spacing
    );
    return node;
  }

  get internalNodesWidth() {
    return this.nodes.length * (this.nodeWidth + this.spacing) - this.spacing;
  }

  get startMovableNodes() {
    return this.nodeWidth + 1.5 * this.spacing;
  }

  get allWidth() {
    return this.internalNodesWidth + 2 * this.nodeWidth + 3 * this.spacing;
  }

  getSpacingX(index: number) {
    return (
      this.startMovableNodes -
      this.spacing / 2 +
      index * (this.nodeWidth + this.spacing)
    );
  }

  get draggingOverPosition() {
    if (this.draggingNodes.length > 0) {
      const n = this.draggingNodes[0];
      const shx = n.shared.xx - this.startMovableNodes;
      const sh = Math.round(shx / (this.nodeWidth + this.spacing));
      if (this.canBeDragTo(n.shared.index, sh)) {
        return sh;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  canBeDragTo(indexFrom: number, indexTo: number) {
    if (
      indexFrom < 0 ||
      indexFrom > this.nodes.length ||
      indexTo < 0 ||
      indexTo > this.nodes.length
    ) {
      return false;
    } else if (indexFrom == indexTo || indexFrom + 1 == indexTo) {
      return false;
    } else {
      return true;
    }
  }

  moveTo(indexFrom: number, indexTo: number) {
    if (this.canBeDragTo(indexFrom, indexTo)) {
      for (const n of this.nodes) {
        n.shared.setAnimateFromIndex(n.shared.index);
      }
      const el = this.nodes[indexFrom];
      this._nodes.splice(indexFrom, 1);
      const to = indexFrom < indexTo ? indexTo - 1 : indexTo;
      this._nodes.splice(to, 0, el);
      this.animationStartedAt = now("frame");
      setTimeout(() => {
        this.stopAnimation();
      }, this.animationDuration);
    }
  }

  clearSelection() {
    for (const n of this.nodes) {
      n.shared.setSelected(false);
    }
  }

  stopAnimation() {
    this.animationStartedAt = null;
    for (const n of this.nodes) {
      n.shared.setAnimateFromIndex(null);
    }
  }

  drop() {
    if (this.draggingOverPosition !== null) {
      this.moveTo(
        this.draggingNodes[0].shared.index,
        this.draggingOverPosition
      );
    }
  }

  get draggingOverX() {
    if (this.draggingOverPosition === null) {
      return null;
    } else {
      const draggingNodeIndex = this.draggingNodes[0].shared.index;
      const r = this.getSpacingX(this.draggingOverPosition);
      console.info(`dragging x=${r} index=${this.draggingOverPosition}`);
      return r;
    }
  }

  get arrows() {
    const arrows = [];
    const rects = [this.startNode, ...Array.from(this.nodes), this.finishNode];
    for (let i = 0; i < rects.length - 1; i++) {
      const r1 = rects[i];
      const r2 = rects[i + 1];
      const b1 = r1.shared.movedBox;
      const b2 = r2.shared.movedBox;
      const arrow = {
        points: getBoxToBoxArrow(
          b1[0],
          b1[1],
          b1[2],
          b1[3],
          b2[0],
          b2[1],
          b2[2],
          b2[3],
          {
            bow: 0.2,
            stretch: 0.5,
            stretchMin: 40,
            stretchMax: 420,
            padStart: 0,
            padEnd: 10,
            flip: false,
            straights: true,
          }
        ),
        id: `${i}`,
      };
      arrows.push(arrow);
    }
    return arrows;
  }

  setLayout(width: number, height: number) {
    console.info(`setLayout ${width} ${height}`);
    this.width = width;
    this.height = height;
  }
}

export interface ILinearNodes {
  readonly shared: LinearNodesShared;
  readonly nodes?: IObservableArray<ILinearNode>;
}

export class DefaultLinearNodes implements ILinearNodes {
  shared: LinearNodesShared = new LinearNodesShared(this);

  constructor() {
    makeAutoObservable(this);
  }
}
