import { IObservableArray, flow, makeAutoObservable, observable } from "mobx";
import {
  ILinearNode,
  LinearNodeShared,
} from "../modules/LinearNodes/models/LinearNodeModel";
import { LutCreator } from "./LutCreator";
import { Property } from "./Property";

export class DefaultLutNode implements ILinearNode {
  lutCreator;
  shared: LinearNodeShared;
  model: any;
  //json: any;
  properties: IObservableArray<Property> = observable.array();

  constructor(lutCreator: LutCreator, m: any) {
    this.lutCreator = lutCreator;
    this.model = m;
    this.shared = new LinearNodeShared(this, lutCreator);
    this.shared.setName(`${m.json.NodeName}`);
    makeAutoObservable(this);
  }
}
