import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { View, Text } from "react-native";
import {
  CurvesPropertiesPanelModel,
  CurvesSubpanels,
} from "../../../models/CurvesPropertiesPanel/CurvesPropertiesPanel";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { Rect } from "../../../utils/Rect";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { CurvesControl } from "./CurvesControl";

type Props = {
  subpanel: CurvesSubpanels;
  model: CurvesPropertiesPanelModel;
};

export const CurvesStack = observer((props: Props) => {
  const { subpanel, model } = props;
  const theme = useTheme();
  const r = model.layout.getSubpanelCurvePos(subpanel);
  const stack = model.rightStack;
  const ref = useRef<View | null>(null);
  const st = {
    //borderColor: theme.colors["panel.border"],
    //backgroundColor: theme.colors["panel.background"],
    ...r.layoutObject,
  };
  if (model) {
    return (
      <View
        ref={ref}
        style={st}
        onLayout={(evt) => {
          stack.setPosition(new Rect(evt.nativeEvent.layout));
          ref.current!.measure(
            (
              fx: number,
              fy: number,
              width: number,
              height: number,
              px: number,
              py: number
            ) => {
              stack.setPositionOnPage(px, py);
            }
          );
        }}
      >
        {stack.notSelectedCurveModels.map((cm) => (
          <CurvesControl model={cm} key={cm.id} />
        ))}
        {stack.selectedCurveModel && (
          <CurvesControl model={stack.selectedCurveModel} />
        )}
      </View>
    );
  } else {
    return <View />;
  }
});
