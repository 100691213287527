import { makeAutoObservable } from "mobx";
import { IBackendApi } from "./api/IBackendApi";
import { LutCreator } from "./LutCreator";

export class StoreRoot {
  width = 100;
  height = 100;
  lutCreator;

  constructor(createApi: () => IBackendApi) {
    this.lutCreator = new LutCreator(this, createApi());
    makeAutoObservable(this);
  }

  setLayout(width: number, height: number): void {
    this.width = width;
    this.height = height;
    this.lutCreator.layout.setSize(width, height);
  }
}
