import { uniqueId } from "lodash";
import {nanoid} from "nanoid";
import { types, getParent } from "mobx-state-tree";

export const BaseProperty = types
  .model("BaseProperty", {
    json: types.frozen(),
  })
  .extend((self) => {
    return {
      views: {
        get node() {
          return getParent(self, 2);
        },
        get nodeIndex() {
          return self.node.index;
        },
        get propertyId() {
          return self.json.id;
        },
      },
      actions: {},
    };
  });

export const FloatProperty = BaseProperty.named("FloatProperty")
  .props({
    json: types.frozen(),
    value: types.optional(types.number, 0.0),
  })
  .extend((self) => {
    return {
      views: {
        get min() {
          return self.json.min || 0.0;
        },
        get max() {
          typeof self.json.max === "number" ? self.json.max : 1.0;
        },
        get defaultValue() {
          typeof self.json.defaultValue === "number"
            ? self.json.defaultValue
            : 0.3;
        },
      },
      actions: {
        setValue(v) {
          self.value = v;
        },
      },
    };
  });

export const IntProperty = BaseProperty.named("IntProperty")
  .props({
    json: types.frozen(),
    value: types.optional(types.number, 0.0),
  })
  .extend((self) => {
    return {
      views: {
        get min() {
          return self.json.min || 0;
        },
        get max() {
          typeof self.json.max === "number" ? self.json.max : 1;
        },
        get defaultValue() {
          typeof self.json.defaultValue === "number"
            ? self.json.defaultValue
            : 0.3;
        },
      },
      actions: {
        setValue(v) {
          self.value = v;
        },
      },
    };
  });

export const DotPointM = types
  .model("DotPoint", {
    x: types.number,
    y: types.number,
    key: types.optional(types.string, () => nanoid()),
  })
  .extend((self) => {
    return {
      views: {
        get index() {
          return getParent(self).indexOf(self);
        },
      },
      actions: {
        setX(v) {
          self.x = v;
        },
        setY(v) {
          self.y = v;
        },
      },
    };
  });

export const GridProperty = BaseProperty.named("GridProperty")
  .props({
    json: types.frozen(),
    width: types.optional(types.number, 1),
    height: types.optional(types.number, 1),
    dots: types.optional(types.array(DotPointM), []),
    circular: types.optional(types.boolean, false),
  })
  .extend((self) => {
    return {
      views: {},
      actions: {
        addDot(d) {
          self.dots.push(d);
        },
        setSize(w, h) {
          self.width = w;
          self.height = h;
        },
        setCircular(c) {
          self.circular = c;
        },
      },
    };
  });

export const CurveProperty = BaseProperty.named("CurveProperty")
  .props({
    json: types.frozen(),
    dots: types.optional(types.array(DotPointM), []),
    //appriximation: types.optional(types.array(types.number), []),
    appriximation: types.frozen([]),
    minX: types.optional(types.number, 0),
    maxX: types.optional(types.number, 16384),
    minY: types.optional(types.number, 0),
    maxY: types.optional(types.number, 32767),
  })
  .extend((self) => {
    return {
      views: {},
      actions: {
        addDot(d) {
          self.dots.push(d);
        },
        clearDots() {
          self.dots.clear();
        },
        setDots(dots) {
          self.dots.splice(0, self.dots.length, ...dots);
        },
        setApproximation(yy) {
          self.appriximation = yy;
        },
      },
    };
  });

export const OtherProperty = BaseProperty.named("OtherProperty")
  .named({
    json: types.frozen(),
  })
  .extend((self) => {
    return { views: {}, actions: { afterCreate() {} } };
  });

function propDispatcher(snapshot) {
  switch (snapshot.json.type) {
    case "integer":
      return IntProperty;
      break;
    case "number":
      return FloatProperty;
      break;
    case "grid":
      return GridProperty;
      break;
    case "curve":
      return CurveProperty;
      break;
    default:
      return OtherProperty;
  }
}

export const AnyProperty = types.union(
  { dispatcher: propDispatcher },
  GridProperty,
  IntProperty,
  FloatProperty,
  CurveProperty,
  OtherProperty
);

export const DefaultNode = types
  .model("DefaultNode", {
    nodeType: types.integer,
    json: types.frozen(),
    properties: types.array(AnyProperty),
  })
  .extend((self) => {
    return {
      views: {
        get index() {
          return getParent(self).indexOf(self);
        },
      },
      actions: {
        createPropertiesByJson() {
          self.properties = Object.values(self.json.properties).map((p) => ({
            json: p,
          }));
        },
        afterCreate() {
          /*console.info(
            "afterCreate",
            Object.keys(self.json.properties),
            self.json.properties
          );*/
          /*self.properties = [];
          for (const p of Object.values(self.json.properties)) {
            //console.info("p=", p);
            self.properties.push({ json: p });
          }*/
          /*console.info("count=", self.properties.length);*/
        },
      },
    };
  });

export const Node = DefaultNode;

export const Lut = types.model("Lut", {}).extend((self) => {
  return { views: {}, actions: {} };
});

export const Preset = types.model("Preset", {}).extend((self) => {
  return { views: {}, actions: {} };
});

export const LutCreatorEngine = types
  .model("LutEngine", {
    lutSize: types.optional(types.number, 16),
    errorMessage: types.optional(types.string, ""),
    preset: types.maybeNull(Preset),
    nodes: types.array(Node),
    updateIndex: types.optional(types.integer, 1),
  })
  .extend((self) => {
    return {
      views: {
        get properties() {
          return this.nodes.map((n) => n.properties).flat();
        },
        getProperty(nodeIndex, propertyId) {
          return this.nodes[nodeIndex].properties.find(
            (p) => p.propertyId == propertyId
          );
        },
      },
      actions: {
        createNode(nodeType, json) {
          self.nodes.push({ nodeType, json });
          self.nodes[self.nodes.length - 1].createPropertiesByJson();
        },
        setErrorMessage(msg) {
          self.errorMessage = msg;
        },
        incUpdateIndex() {
          self.updateIndex += 1;
        },
        clearNodes() {
          self.nodes.clear();
        },
      },
    };
  });
