import React, { ReactChild, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { PanResponder, Animated, StyleSheet, View } from "react-native";
import { ILinearNode } from "../models/LinearNodeModel";
import { NodeContent } from "./NodeContent";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import ErrorBoundary from "react-native-error-boundary";

type Props = {
  node: ILinearNode;
  renderNodeContent?(node: ILinearNode): ReactChild;
  customRenderer?(node: ILinearNode): boolean;
};

export const LinearNode = observer((props: Props) => {
  const { node, customRenderer, renderNodeContent } = props;
  const theme = useTheme();
  const left = node.shared.animating ? node.shared.animatingX : node.shared.xx;
  const elevation = node.shared.dragging ? 4 : 1;
  const style = {
    ...StyleSheet.absoluteFillObject,
    borderWidth: 1,
    borderColor: node.shared.animating
      ? theme.colors["charts.red"]
      : node.shared.movable
      ? theme.colors["charts.lines"]
      : theme.colors["editorGhostText.foreground"],
    backgroundColor: theme.colors["sideBar.background"],
    width: node.shared.width,
    height: node.shared.height,
    display: "flex",
    alignItems: "center",
    elevation,
    zIndex: elevation,
    justifyContent: "center",
    padding: 5,
    left,
    top: node.shared.yy,
    userSelect: "none",
  } as any;

  if (node.shared.selected) {
    style.borderWidth = 2;
  }

  return (
    <ErrorBoundary>
      {node.shared.dragging && (
        <View
          style={{
            opacity: 0.5,
            ...style,
            //borderColor: "silver",
            left: node.shared.animating
              ? node.shared.animatingX
              : node.shared.x,
            top: node.shared.y,
          }}
        >
          <NodeContent node={node} />
        </View>
      )}
      <Animated.View
        style={style}
        {...(node.shared.movable ? node.shared.panResponder.panHandlers : {})}
      >
        {customRenderer && renderNodeContent && customRenderer(node) ? (
          renderNodeContent(node)
        ) : (
          <NodeContent node={node} />
        )}
      </Animated.View>
    </ErrorBoundary>
  );
});
