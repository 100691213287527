import React, { ReactNode } from "react";
import { themes } from "./Themes";

const initialState = {
  themes,
};

export const ThemeContext = React.createContext(initialState);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [dark, setDark] = React.useState(true);

  React.useEffect(() => {
    const isDark = localStorage.getItem("dark") === "true";
    setDark(isDark);
  }, [dark]);

  const toggle = () => {
    const isDark = !dark;
    localStorage.setItem("dark", JSON.stringify(isDark));
    setDark(isDark);
  };

  //const theme = dark ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider value={{ themes }}>{children}</ThemeContext.Provider>
  );
}

export function useTheme() {
  const { themes } = React.useContext(ThemeContext);
  return themes.currentTheme;
}