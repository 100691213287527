import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { CurvePanelState } from "../../../models/CurvesPropertiesPanel/CurvesPropertiesPanel";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { Rect } from "../../../utils/Rect";
import { createMenuTheme } from "../../../utils/theme/menuTheme";
import { useTheme } from "../../../utils/theme/ThemesProvider";

export type MenuItems = Array<{ label: string; onClick: () => void }>;

type Props = {
  node: DefaultLutNode;
  renderToolbar?: (where: Rect) => any;
  menuItems?: MenuItems;
};

export const PanelToolbar = observer((props: Props) => {
  const { node, renderToolbar, menuItems } = props;
  const r = node.lutCreator.layout.nodePropertiesToolbarPosOnPanel;
  const theme = useTheme();
  const {
    tabsTheme: { style: ts },
  } = theme;
  const renderMenu = () => {
    const s = createMenuTheme(theme);
    return (
      <Menu
        style={{
          margin: 8,
          //height: ts.tabHeight,
          maxWidth: 200,
          backgroundColor: theme.tabsTheme.style.inactiveTabBackground,
        }}
      >
        <MenuTrigger customStyles={s.triggerStyles} text={"☰"}></MenuTrigger>
        <MenuOptions customStyles={s.optionsStyle}>
          {menuItems &&
            menuItems.map((k) => {
              return (
                <MenuOption
                  key={k.label}
                  customStyles={s.optionStyle}
                  onSelect={() => {
                    k.onClick();
                  }}
                  text={k.label}
                />
              );
            })}
        </MenuOptions>
      </Menu>
    );
  };
  return (
    <View
      style={{
        //borderColor: ts.borders,
        backgroundColor: ts.inactiveTabBackground,
        //borderWidth: 1,
        ...r.layoutObject,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        //justifyContent: "center",
      }}
    >
      {renderToolbar && renderToolbar(r)}
      {menuItems && renderMenu()}
    </View>
  );
});
