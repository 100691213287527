import { makeAutoObservable } from "mobx";
import { Instance } from "mobx-state-tree";
import {
  scale,
  compose,
  translate,
  inverse,
  applyToPoint,
  identity,
} from "transformation-matrix";
import { PanResponder, PanResponderGestureState } from "react-native";
import { CurveInfo, CurveStack } from "./CurveStack";
import {
  CurveProperty,
  DotPointM,
  IntProperty,
} from "../../LutEngineModel/LutEngineModel";
import { createAkimaSplineInterpolator } from "commons-math-interpolation";
import { domainToASCII } from "url";

const DOT_RADIUS_PIXELS = 8;

function nonZero(v: number) {
  const epsilon = 0.00001;
  return Math.abs(v) < epsilon ? (v < 0 ? -epsilon : epsilon) : v;
}

function distance(p1: PointArrayNotation, p2: PointArrayNotation): number {
  const x = p1[0] - p2[0];
  const y = p1[1] - p2[1];
  return Math.sqrt(x * x + y * y);
}

function mirrorPoint(center: PointArrayNotation, point: PointArrayNotation) {
  return [
    center[0] - (point[0] - center[0]),
    center[1] - (point[1] - center[1]),
  ];
}

export class CurveModel {
  padding = 10;
  moved = false;
  overPosition: PointArrayNotation | null = null;
  curveStack: CurveStack;
  info: CurveInfo;
  pickedDot: null | Instance<typeof DotPointM> = null;
  pickedDotOriginalPosition: PointArrayNotation | null = null;
  movingShift: PointArrayNotation | null = null;
  currentDotKey: string | null = null;

  constructor(curveStack: CurveStack, info: CurveInfo) {
    this.curveStack = curveStack;
    this.info = info;
    makeAutoObservable(this);
  }

  setPickedDot(dot: null | Instance<typeof DotPointM>) {
    console.info("setPickedDot", dot);
    this.pickedDot = dot;
  }

  get currentDot() {
    return this.dots.find((d) => d.key === this.currentDotKey) || null;
  }

  get layout() {
    return this.curveStack.layout;
  }

  get property(): Instance<typeof CurveProperty> | null {
    return this.curveStack.panel.node.model.properties.find(
      (p: Instance<typeof CurveProperty>) => p.json.id === this.info.id
    );
  }

  get pointsWithExtrapolation() {
    if (this.property && this.dots.length > 0) {
      const l = this.dots.length;
      if (l > 1) {
        let l2, l1, r1, r2;
        l1 = mirrorPoint(
          [this.dots[0].x, this.dots[0].y],
          [this.dots[1].x, this.dots[1].y]
        );
        r1 = mirrorPoint(
          [this.dots[l - 1].x, this.dots[l - 1].y],
          [this.dots[l - 2].x, this.dots[l - 2].y]
        );
        if (l > 2) {
          l2 = mirrorPoint(
            [this.dots[0].x, this.dots[0].y],
            [this.dots[2].x, this.dots[2].y]
          );
          r2 = mirrorPoint(
            [this.dots[l - 1].x, this.dots[l - 1].y],
            [this.dots[l - 3].x, this.dots[l - 3].y]
          );
        } else {
          l2 = [...l1];
          r2 = [...r1];
          l2[0] -= this.dots[0].x - l1[0];
          l2[1] -= this.dots[0].y - l1[1];
          r2[0] -= this.dots[l - 1].x - r1[0];
          r2[1] -= this.dots[l - 1].y - r1[1];
        }
        return [l2, l1, ...this.dots.map((d) => [d.x, d.y]), r1, r2];
      } else {
        const { x, y } = this.dots[0];
        return [
          [x - 200, y],
          [x - 100, y],
          [x, y],
          [x + 100, y],
          [x + 200, y],
        ];
      }
    } else {
      return [];
    }
  }

  get pointsWithExtrapolation1() {
    const f = this.dots[0];
    const l = this.dots[this.dots.length - 1];
    if (this.property) {
      const pp1 = [];
      const pp2 = [];
      const interpolateMissingPointsLeft = () => {
        const pm2 = [];
        const pm1 = [];
        const m_m1 =
          (this.dots[1].y - this.dots[0].y) /
          nonZero(this.dots[1].x - this.dots[0].x);
        let m_m2;
        if (this.dots.length > 2) {
          m_m2 =
            (this.dots[2].y - this.dots[1].y) /
            nonZero(this.dots[2].x - this.dots[1].x);
        } else {
          m_m2 = 0;
        }
        pm1[0] = this.dots[0].x + this.dots[1].x - this.dots[2].x;
      };
      /*const sh = (this.property?.maxX - this.property?.minX) / 0.1;
      [
        [f.x - 2 * sh, f.y],
        [f.x - sh, f.y],
        ...this.dots.map((d) => [d.x, d.y]),
        [l.x + sh, l.y],
        [l.x + 2 * sh, l.y],
      ];*/
    } else {
      return [];
    }
  }

  get interpolator() {
    console.info("extrapolation", this.pointsWithExtrapolation);
    return createAkimaSplineInterpolator(
      this.pointsWithExtrapolation.map((d) => d[0]),
      this.pointsWithExtrapolation.map((d) => d[1])
    );
  }

  get enabledProperty() {
    if (this.info.enabledId) {
      const p: Instance<typeof IntProperty> =
        this.curveStack.panel.node.model.properties.find(
          (p: Instance<typeof IntProperty>) => p.json.id === this.info.enabledId
        );
      return p;
    } else {
      return null;
    }
  }

  get isEnabled() {
    if (this.enabledProperty) {
      return !!this.enabledProperty.value;
    } else {
      return true;
    }
  }

  setEnabled(v: boolean) {
    if (this.enabledProperty) {
      this.lutCreator.setIntValue(
        this.enabledProperty.nodeIndex,
        this.enabledProperty.propertyId,
        v ? 1 : 0
      );
    } else {
      console.error("setEnabled");
    }
  }

  get lutCreator() {
    return this.curveStack.panel.lutCreator;
  }

  setOverPosition(inDoc: PointArrayNotation | null) {
    this.overPosition = inDoc;
  }

  get overDot() {
    if (this.overPosition) {
      const dot = this.findDotAtCoordinates(this.overPosition);
      return dot;
    } else {
      return null;
    }
  }

  get isSelected() {
    return this.curveStack.selectedCurveModel === this;
  }

  get overPositionOnSvg() {
    if (this.overPosition) {
      return applyToPoint(this.matrixDocToSvg, this.overPosition);
    } else {
      return null;
    }
  }

  get dots() {
    return (this.property ? this.property.dots : []) as Array<
      Instance<typeof DotPointM>
    >;
  }

  setMoved(m: boolean) {
    this.moved = m;
  }

  get rect() {
    return this.layout.rect;
  }

  setCurrentDot(p: Instance<typeof DotPointM> | null) {
    if (p) {
      this.currentDotKey = p.key;
    } else {
      this.currentDotKey = null;
    }
  }

  setPickedDotOriginalPosition(p: PointArrayNotation | null) {
    this.pickedDotOriginalPosition = p;
  }

  setMovingShift(shift: PointArrayNotation | null) {
    this.movingShift = shift;
    console.info("setMovingShift", shift);
    if (
      this.property &&
      this.pickedDot &&
      this.pickedDotOriginalPosition &&
      shift
    ) {
      const scalex = this.matrixSvgToDoc.a;
      const scaley = this.matrixSvgToDoc.d;
      this.pickedDot.setX(
        this.pickedDotOriginalPosition[0] + scalex * shift[0]
      );
      this.pickedDot.setY(
        this.pickedDotOriginalPosition[1] - scaley * shift[1]
      );
      this.lutCreator.api.updateCurveDotPos(
        this.property.nodeIndex,
        this.property.propertyId,
        this.pickedDot.index,
        Math.round(this.pickedDot.x),
        Math.round(this.pickedDot.y)
      );
    }
  }

  findDotAtCoordinates(
    p: PointArrayNotation
  ): Instance<typeof DotPointM> | null {
    const scale = this.matrixSvgToDoc.a;
    const maxDist = DOT_RADIUS_PIXELS * scale;
    const dot = this.dots.find((d) => distance(p, [d.x, d.y]) < maxDist) as
      | Instance<typeof DotPointM>
      | undefined;
    return dot || null;
  }

  fromGestureCoordinatesToDocument(
    point: PointArrayNotation
  ): PointArrayNotation {
    const viewP: PointArrayNotation = [
      point[0] - this.curveStack.pageX,
      point[1] - this.curveStack.pageY,
    ];
    const [x, y] = applyToPoint(
      this.matrixSvgToDoc,
      applyToPoint(this.rotateSvg, viewP)
    );
    return [x, y];
  }

  get panResponder() {
    return PanResponder.create({
      onStartShouldSetPanResponder: (_e, gesture) => true,
      onPanResponderGrant: (e, gesture) => {
        this.setMoved(false);
        const pos = this.fromGestureCoordinatesToDocument([
          gesture.x0,
          gesture.y0,
        ]);
        const dot = this.findDotAtCoordinates(pos);
        this.setPickedDot(dot);
        if (dot) {
          this.setPickedDotOriginalPosition([dot.x, dot.y]);
        }
        this.setCurrentDot(dot);
        console.info(`onPanResponderGrant`, JSON.stringify(gesture));
      },
      onPanResponderMove: (e, gesture) => {
        console.info(`onPanResponderMove.moved=${this.moved}`);
        if (this.moved) {
          this.setMovingShift([gesture.dx, gesture.dy]);
        } else {
          const q = gesture.dx * gesture.dx + gesture.dy * gesture.dy;
          if (q > 5) {
            this.setMoved(true);
            this.setMovingShift([gesture.dx, gesture.dy]);
          }
        }
      },
      onPanResponderRelease: (e, gesture) => {
        console.info("moved", this.moved);
        if (this.moved) {
          console.info("onPanResponderRelease.drop");
        } else {
          console.info("onPanResponderRelease.click");
          this.click(gesture);
        }
        this.setMovingShift(null);
        this.setPickedDot(null);
      },
    });
  }

  click(gesture: PanResponderGestureState) {
    const [x, y] = this.fromGestureCoordinatesToDocument([
      gesture.x0,
      gesture.y0,
    ]);
    const y1 = this.getY(x);
    this.addPoint([x, y1]);
  }

  get matrixSvgToDoc() {
    return inverse(this.matrixDocToSvg);
  }

  get matrixDocToSvg() {
    if (this.property) {
      const sx =
        (this.rect.width - 2 * this.padding) /
        (this.property.maxX - this.property.minX);
      const sy =
        (this.rect.height - 2 * this.padding) /
        (this.property.maxY - this.property.minY);
      return compose(translate(this.padding, this.padding), scale(sx, sy));
    } else {
      return identity();
    }
  }

  get approximationPoints() {
    const p = this.property;
    if (p && p.appriximation.length) {
      return p.appriximation.map((yd: number, i: number) => {
        const xd = ((p.maxX - p.minX) / p.appriximation.length) * i;
        const svgCoordinates = applyToPoint(this.matrixDocToSvg, [
          Math.round(xd),
          Math.round(yd),
        ]);
        return svgCoordinates;
      });
    } else {
      return [];
    }
  }

  getY(x: number) {
    const p = this.property;
    if (p) {
      const limitY = (y: number) =>
        y > p.maxY ? p.maxY : y < p.minY ? p.minY : y;
      if (this.dots.length == 0) {
        return p.minY + ((x - p.minX) * (p.maxY - p.minY)) / (p.maxX - p.minX);
      } else if (this.dots.length == 1) {
        return this.dots[0].y;
      } else if (x < this.dots[0].x) {
        return limitY(this.dots[0].y);
      } else if (x > this.dots[this.dots.length - 1].x) {
        return limitY(this.dots[this.dots.length - 1].y);
      } else {
        return limitY(this.interpolator(x));
      }
    } else {
      return 0;
    }
  }

  get approximationPointsCS() {
    const p = this.property;
    const res: Array<PointArrayNotation> = [];
    if (p && p.dots.length > 1) {
      const ad = 256;
      for (let ix = 0; ix < ad; ix++) {
        const x = (ix * (p.maxX - p.minX)) / (ad - 1) + p.minX;
        const y = this.getY(x);
        const svgCoordinates = applyToPoint(this.matrixDocToSvg, [
          Math.round(x),
          Math.round(y),
        ]);
        res[ix] = svgCoordinates;
      }
    }
    return res;
  }

  get approximationPathCS() {
    return (
      "M " +
      this.approximationPointsCS
        .map((ap: PointArrayNotation) => ap.join(" "))
        .join(" L ") +
      ""
    );
  }

  get approximationPath() {
    return (
      "M " +
      this.approximationPoints
        .map((ap: PointArrayNotation) => ap.join(" "))
        .join(" L ") +
      ""
    );
  }

  get rotateSvg() {
    return compose(translate(0, this.rect.height), scale(1, -1));
  }

  get id() {
    return this.property?.propertyId;
  }

  get x() {
    return this.curveStack.rect.x;
  }

  get y() {
    return this.curveStack.rect.y;
  }

  get width() {
    return this.curveStack.rect.width;
  }

  get height() {
    return this.curveStack.rect.height;
  }

  addPoint(p: PointArrayNotation) {
    if (this.property) {
      const r = [
        p,
        ...this.dots.map(
          (d: Instance<typeof DotPointM>): PointArrayNotation => [d.x, d.y]
        ),
      ];
      const r1 = r.sort((a, b) => a[0] - b[0]);
      this.lutCreator.setCurvePoints(
        this.property.nodeIndex,
        this.property.propertyId,
        r1
      );
    } else {
      console.error("no property", this.info);
    }
  }
}
