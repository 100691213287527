import { makeAutoObservable, makeObservable } from "mobx";
import { Rect } from "../../utils/Rect";
import { themes } from "../../utils/theme/Themes";
import { CurveStack } from "./CurveStack";
import * as kiwi from "@lume/kiwi";

export class CurveLayout {
  curveStack: CurveStack;

  solver = new kiwi.Solver();
  canvasSize = new kiwi.Variable("canvasSize");
  svgSize = new kiwi.Variable("svgSize");

  constructor(curveStack: CurveStack) {
    this.curveStack = curveStack;
    makeObservable(this, { curveStack: true });
    this.solver.addEditVariable(this.svgSize, 1);
    this.solver.addEditVariable(this.canvasSize, 1);
  }

  get theme() {
    return themes.currentTheme;
  }

  get rect() {
    return this.curveStack.rect;
  }

  get svgElementRect() {
    const P = this.theme.space[3];
    const r = this.rect;
    return new Rect(r.x + P, r.y + P, r.width - 2 * P, r.height - 2 * P);
  }

  get xadd() {
    const P = this.theme.space[3];
    return 4 * P;
  }

  get yadd() {
    const P = this.theme.space[3];
    return 4 * P;
  }

  get curveCanvasRectInSvg() {
    const P = this.theme.space[3];
    const r = this.svgElementRect;
    return new Rect(r.x + P, r.y + P, r.width - 2 * P, r.height - 2 * P);
  }
}
