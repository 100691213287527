import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import {
  CurvePanelState,
  CurvesPropertiesPanelModel,
  CurvesSubpanels,
} from "../../../models/CurvesPropertiesPanel/CurvesPropertiesPanel";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { Rect } from "../../../utils/Rect";
import { PanelWithTitle } from "../PanelWithTitle/PanelWithTitle";
import { CurvesPanelBody } from "./CurvesPanelBody";
import { CurvesToolbarTabs } from "./CurvesToolbarTabs";

type Props = {
  node: DefaultLutNode;
};

export const CurvesPropertiesPanel = observer((props: Props) => {
  const { node } = props;
  const [model, setModel] = useState<CurvesPropertiesPanelModel | null>(null);
  useEffect(() => {
    setModel(new CurvesPropertiesPanelModel(node));
  }, []);
  if (model) {
    const l = model.layout;
    return (
      <PanelWithTitle
        node={node}
        renderBody={function (where: Rect) {
          return (
            <CurvesPanelBody model={model} subpanel={CurvesSubpanels.LEFT} />
          );
        }}
        renderToolbar={function (where: Rect) {
          return <CurvesToolbarTabs model={model} />;
        }}
        menuItems={[
          CurvePanelState.CURVE,
          CurvePanelState.PROPS,
          CurvePanelState.JSON,
        ].map((k) => ({ label: k, onClick: () => model.setState(k) }))}
      />
    );
  } else {
    return <View />;
  }
});
