import { makeAutoObservable } from "mobx";
import { Rect } from "../utils/Rect";
import { themes } from "../utils/theme/Themes";
import { LutCreator } from "./LutCreator";

export class LutCreatorLayout {
  editor;
  width = 100;
  height = 100;

  constructor(editor: LutCreator) {
    this.editor = editor;
    makeAutoObservable(this);
  }

  setSize(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  get spacing() {
    return 6;
  }

  get separatorX() {
    return this.width / 2;
  }

  get footerHeight() {
    return 40;
  }

  get mainToolbarHeight() {
    return 40;
  }

  get previewToolbarHeight() {
    return 40;
  }

  get nodesHeight() {
    return 100;
  }

  get nodesPanelRect(): Rect {
    return new Rect([
      this.spacing / 2,
      this.height - this.footerHeight - this.nodesHeight - this.spacing,
      this.width / 2 - this.spacing,
      this.nodesHeight,
    ]);
  }

  get mainToolbarRect() {
    return new Rect(0, 0, this.width, this.mainToolbarHeight);
  }

  get nodePropertiesRect(): Rect {
    const p = this.nodesPanelRect;
    return new Rect(
      this.spacing / 2,
      this.spacing * 1.5 + this.mainToolbarHeight,
      p.width,
      this.height -
        p.height -
        3 * this.spacing -
        this.footerHeight -
        this.mainToolbarHeight
    );
  }

  get nodePropertiesToolbarPosOnPanel() {
    let res = { ...this.nodePropertiesRect.obj, x: 0, y: 0 };
    res.height = this.nodePropertiesToolbarHeight + 2;
    return new Rect(res);
  }

  get nodePropertiesPanelBodyOnPanel() {
    let res = { ...this.nodePropertiesRect.obj, x: 0, y: 0 };
    res.height -= this.nodePropertiesToolbarHeight;
    res.y += this.nodePropertiesToolbarHeight;
    return new Rect(res);
  }

  get nodePropertiesToolbarHeight() {
    const t = themes.currentTheme;
    return t.tabsTheme.style.tabHeight;
  }

  get previewToolbarRect(): Rect {
    const p = this.nodePropertiesRect;
    return new Rect(
      this.width / 2,
      this.height -
        this.previewToolbarHeight -
        this.spacing -
        this.footerHeight,
      this.width / 2,
      this.previewToolbarHeight
    );
  }

  get previewPanelRect(): Rect {
    const p = this.previewToolbarRect;
    const y = this.nodePropertiesRect.y;
    const h = p.y - y - this.spacing;
    return new Rect(this.width / 2, y, p.width, h);
  }

  get footerRect(): Rect {
    return new Rect(
      0,
      this.height - this.footerHeight,
      this.width,
      this.footerHeight
    );
  }
}
