import { observer } from "mobx-react";
import React from "react";
import { View, StyleSheet } from "react-native";
import { DisplacementDot } from "../model/DisplacementDot";
import { PINNED_DOT_SIZE, DOT_SIZE } from "../settngs";

type Props = {
  dot: DisplacementDot;
};

export const DotComponentView = observer((props: Props) => {
  const { dot } = props;
  const [x, y] = dot.svgCoordinates;
  const size = dot.pinned ? PINNED_DOT_SIZE : DOT_SIZE;
  return (
    <View
      {...dot.panResponder.panHandlers}
      style={{
        ...StyleSheet.absoluteFillObject,
        left: x - size / 2,
        top: y - size / 2,
        width: size,
        height: size,
        backgroundColor: "silver",
        borderColor: "black",
        borderWidth: 2,
      }}
    />
  );
});
