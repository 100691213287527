import { flow, makeAutoObservable, IObservableArray, observable } from "mobx";
import { LutCreatorEngine } from "../LutEngineModel/LutEngineModel";
import { ILinearNode } from "../modules/LinearNodes/models/LinearNodeModel";
import {
  ILinearNodes,
  LinearNodesShared,
} from "../modules/LinearNodes/models/LinearNodesModel";
import { applySnapshot, applyPatch, getSnapshot } from "mobx-state-tree";
import { DefaultLutNode } from "./DefaultLutNode";
import { LutPreview } from "./LutPreview/LutPreview";
import { StoreRoot } from "./StoreRoot";
import { throttle, delay } from "lodash";
import { IBackendApi } from "./api/IBackendApi";
import { LutCreatorLayout } from "./LutCreatorLayout";

export class LutCreator implements ILinearNodes {
  layout = new LutCreatorLayout(this);
  shared: LinearNodesShared;
  lutCanvas: null | HTMLCanvasElement | HTMLImageElement = null;
  drawIndex = -1;
  preview: LutPreview;
  root;
  engine = LutCreatorEngine.create();
  isLutLoading = false;
  loadLutIfNeededDebounced = throttle(() => this.loadLutIfNeeded(), 5);
  api;

  constructor(root: StoreRoot, api: IBackendApi) {
    this.api = api;
    this.api.setLutCreator(this);
    this.root = StoreRoot;
    this.preview = new LutPreview(this);
    this.shared = new LinearNodesShared(this);
    makeAutoObservable(this);
    this.api.create();
  }

  get nodes() {
    return observable.array(
      this.engine.nodes.map((n) => new DefaultLutNode(this, n))
    );
  }

  get snapshot() {
    return getSnapshot(this.engine);
  }

  setCurvePoints(
    nodeIndex: number,
    propertyId: number,
    points: Array<PointArrayNotation>
  ) {
    this.api.setCurvePoints(nodeIndex, propertyId, points);
  }

  setIntValue(nodeIndex: number, propertyId: number, value: number) {
    this.api.setIntValue(nodeIndex, propertyId, value);
  }

  setNumberValue(nodeIndex: number, propertyId: number, value: number) {
    this.api.setNumberValue(nodeIndex, propertyId, value);
  }

  loadLutIfNeeded = flow(function* loadLutIfNeeded(this: LutCreator) {
    if (this.drawIndex === this.engine.updateIndex) {
      return;
    } else if (this.isLutLoading) {
      this.loadLutIfNeededDebounced();
      //delay(() => this.loadLutIfNeeded(), 10);
    } else {
      try {
        this.isLutLoading = true;
        this.api.loadLut(
          this.lutCanvas !== null && this.lutCanvas instanceof HTMLCanvasElement
            ? this.lutCanvas
            : null
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLutLoading = false;
      }
    }
  });

  get selectedNode() {
    if (this.shared.selectedNode) {
      return this.shared.selectedNode as DefaultLutNode;
    } else {
      return null;
    }
  }

  setLutCanvas(
    c: HTMLCanvasElement | HTMLImageElement | null,
    drawIndex: number
  ) {
    this.lutCanvas = c;
    this.drawIndex = drawIndex;
    console.info(`drawIndex=${this.drawIndex}`);
  }

  applySnapshot(snapshot: any) {
    applySnapshot(this.engine, snapshot);
    this.loadLutIfNeededDebounced();
    const sn = getSnapshot(this.engine);
    console.info("sn=", sn);
  }

  applyPatches(patches: any[]) {
    console.info("patches", patches);
    for (const p of patches) {
      applyPatch(this.engine, p);
    }
    this.loadLutIfNeededDebounced();
  }

  create = flow(function* (this: LutCreator) {
    //this.api.create()
  });
}
