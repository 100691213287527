import React, { useEffect } from "react";
import { observer, Observer } from "mobx-react";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { CurvesPropertiesPanel } from "../CurvesPropertiesPanel/CurvesPropertiesPanel";
import { GridPanel } from "../GridPanel/GridPanel";
import { DefaultPropertiesPanel } from "../DefaultPropertiesPanel/DefaultPropertiesPanel";

type Props = {
  node: DefaultLutNode;
};

export const PropertiesPanelRoot = observer((props: Props) => {
  const { node } = props;
  const r = node.lutCreator.layout.nodePropertiesRect;
  const theme = useTheme();
  const st = {
    borderColor: theme.colors["panel.border"],
    backgroundColor: theme.colors["panel.background"],
    ...r.layoutObject,
  };
  if (node.model.nodeType == 13) {
    return <CurvesPropertiesPanel node={node} />;
  } else if (node.model.nodeType == 7) {
    return <GridPanel node={node} />;
  } else {
    return <DefaultPropertiesPanel node={node} />;
  }
});
