import { observer } from "mobx-react";
import React from "react";
import { View, Text, ScrollView } from "react-native";
import { LutCreator } from "../../models/LutCreator";
import { PreviewState } from "../../models/LutPreview/LutPreview";
import JSONTree from "react-native-json-tree";

type Props = { lutCreator: LutCreator, style: any };

export const DocumentJsonTree = observer((props: Props) => {
  const {
    lutCreator,
    style,
    lutCreator: { preview },
  } = props;
  const rect = lutCreator.layout.previewToolbarRect;
  return (
    <ScrollView style={style}>
      <JSONTree data={lutCreator.snapshot as any} />
    </ScrollView>
  );
});
