import { makeAutoObservable, observable } from "mobx";
import { compose, inverse, scale, translate } from "transformation-matrix";
import { Rect } from "../../../utils/Rect";

export class GridCoordinates {
  minX = 0;
  minY = 0;
  maxX = 100.0;
  maxY = 100.0;
  padding = 10;
  canvasRect = new Rect(0, 0, 100, 100);

  constructor() {
    makeAutoObservable(this, { canvasRect: observable.ref });
  }

  setRange({
    minX,
    maxX,
    minY,
    maxY,
  }: {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  }) {
    this.minX = minX;
    this.maxX = maxX;
    this.minY = minY;
    this.maxY = maxY;
  }

  setCanvasPosition(rect: Rect) {
    this.canvasRect = rect;
  }

  get viewBox() {
    return `0 0 ${this.canvasWidth} ${this.canvasHeight}`;
  }

  get canvasWidth() {
    return this.canvasRect.width;
  }
  get canvasHeight() {
    return this.canvasRect.height;
  }

  get activeWidth() {
    return this.canvasWidth - 2 * this.padding;
  }
  get activeHeight() {
    return this.canvasHeight - 2 * this.padding;
  }

  get modelToSvgMatrix() {
    return compose(
      translate(this.padding, this.padding),
      scale(
        this.activeWidth / (this.maxX - this.minX),
        this.activeHeight / (this.maxY - this.minY)
      ),
      translate(-this.minX, -this.minY)
    );
  }

  get svgToModelMatrix() {
    return inverse(this.modelToSvgMatrix);
  }
}
