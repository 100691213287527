import { observer } from "mobx-react";
import { DisplacementGrid } from "../model/DisplacementGrid";
import { DisplacementGridToolbar } from "./DisplacementGridToolbar";
import React from "react";
import { StyleSheet } from "react-native";
import { DisplacementGridCanvas } from "./DisplacementGridCanvas";

type Props = {
  grid: DisplacementGrid;
  style?: any;
};

export const DisplacementGridComponent = observer((props: Props) => {
  const { grid, style } = props;
  const padding = 5;
  //<DisplacementGridToolbar grid={grid} />
  return (
    <div
      style={{
        ...StyleSheet.absoluteFillObject,
        //display: "flex",
        width: grid.controlWidth,
        height: grid.controlHeight,
        marginTop: padding,
        backgroundColor: 'var(--spectrum-well-background-color, "black")',
        border: `1px solid var(--spectrum-well-border-color, "black")`,
      }}
    >
      <DisplacementGridCanvas grid={grid} />
    </div>
  );
});
