import { makeAutoObservable, observable } from "mobx";
import { Rect } from "../../utils/Rect";
import { CurveLayout } from "./CurveLayout";
import { CurveModel } from "./CurveModel";
import { CurvesPropertiesPanelModel } from "./CurvesPropertiesPanel";

export type CurveInfo = {
  title: string;
  id: number;
  color: string;
  enabledId?: number;
};

const gradientPanelWidth = 10;

export class CurveStack {
  panel;
  pageX = 0;
  pageY = 0;
  infos;
  curveModels = observable.array();
  selectedCurveIndex = 0;
  _rect = new Rect(0, 0, 100, 100);
  layout = new CurveLayout(this);

  constructor(panel: CurvesPropertiesPanelModel, infos: Array<CurveInfo>) {
    this.panel = panel;
    this.infos = infos;
    makeAutoObservable(this);
    this.loadCurveModels();
  }

  get selectedCurveInfo() {
    const info = this.infos[this.selectedCurveIndex];
    return info ? info : null;
  }

  setPositionOnPage(px: number, py: number) {
    this.pageX = px;
    this.pageY = py;
  }

  get selectedCurveModel() {
    const info = this.curveModels[this.selectedCurveIndex];
    return info ? info : null;
  }

  get rect() {
    return this._rect;
  }

  get canvasRect() {
    const res = this.rect;
    return res;
  }

  setPosition(rect: Rect) {
    this._rect = rect;
  }

  setSelectedCurveIndex(index: number): void {
    this.selectedCurveIndex = index;
  }

  get notSelectedCurveModels() {
    return this.curveModels.filter((m, i) => i !== this.selectedCurveIndex);
  }

  loadCurveModels() {
    this.curveModels.clear();
    for (const info of this.infos) {
      this.curveModels.push(new CurveModel(this, info));
    }
  }
}
