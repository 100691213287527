import { observer } from "mobx-react";
import { DisplacementGrid } from "../model/DisplacementGrid";
import React from "react";
import { Svg, Line } from "react-native-svg";
import { StyleSheet } from "react-native";
import { DotComponentView } from "./DotComponentView";

type Props = {
  grid: DisplacementGrid;
  style?: any;
};

export const DisplacementGridCanvas = observer((props: Props) => {
  const { grid, style } = props;
  const padding = 0;
  return (
    <div
      style={{
        ...StyleSheet.absoluteFillObject,
        overflow: "hidden",
        top: grid.toolbarHeight,
        width: grid.coordinates.canvasWidth,
        height: grid.coordinates.canvasHeight,
      }}
    >
      <Svg
        style={{
          width: grid.coordinates.canvasWidth,
          height: grid.coordinates.canvasHeight,
          ...StyleSheet.absoluteFillObject,
        }}
        viewBox={grid.coordinates.viewBox}
      >
        {grid.dots!.lines.map(([from, to]) => (
          <Line
            key={`${from.id}-${to.id}`}
            x1={from.svgCoordinates[0]}
            y1={from.svgCoordinates[1]}
            x2={to.svgCoordinates[0]}
            y2={to.svgCoordinates[1]}
            stroke={"white"}
          />
        ))}
      </Svg>
      {grid.dots!.dots.map((dot) => (
        <DotComponentView dot={dot} key={dot.id} />
      ))}
    </div>
  );
});
