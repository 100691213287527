import React, { useEffect } from "react";
import { ScrollView, View, Text } from "react-native";
import { observer, Observer } from "mobx-react";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { useTheme } from "../../../utils/theme/ThemesProvider";
import { getType, Instance } from "mobx-state-tree";
import {
  CurveProperty,
  GridProperty,
  FloatProperty,
  AnyProperty,
} from "../../../LutEngineModel/LutEngineModel";
import { NumberProperty as NumberPropertyComponent } from "./NumberProperty";
import { DefaultProperty as DefaultPropertyComponent } from "./DefaultProperty";
import { GridPropertyComponent } from "./GridProperty";
//import { CurvePropertyComponent } from "./CurveProperty";
import { CurvesPropertiesPanel } from "../CurvesPropertiesPanel/CurvesPropertiesPanel";

type Props = {
  node: DefaultLutNode;
};

export const PropertiesList = observer((props: Props) => {
  const { node } = props;
  const r = node.lutCreator.layout.nodePropertiesRect;
  const theme = useTheme();
  const st = {
    ...r.layoutObject,
  };
  return (
    <ScrollView style={st}>
      {node.model.properties.map((p: Instance<typeof AnyProperty>) => {
        return (
          <Observer key={p.json.id}>
            {() => {
              switch (getType(p)) {
                case FloatProperty:
                  return <NumberPropertyComponent node={node} p={p} />;
                case GridProperty:
                  return <GridPropertyComponent node={node} p={p} />;
                case CurveProperty:
                  return <Text>Curve TODO</Text>;
                //return <CurvePropertyComponent node={node} p={p} />;
                default:
                  return <DefaultPropertyComponent node={node} p={p} />;
              }
            }}
          </Observer>
        );
      })}
    </ScrollView>
  );
});
