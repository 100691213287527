import { uniqueId } from "lodash";
import { makeAutoObservable } from "mobx";
import { PanResponder } from "react-native";
import { applyToPoint } from "transformation-matrix";
import { DisplacementGridDots } from "./DisplacementGridDots";

export class DisplacementGridDot {
  id = uniqueId("dot");
  gridDots;

  x = 0;
  y = 0;
  brightness = 0;
  numCalculated = 0;
  toCalcStep = 0;
  col = 0;
  row = 0;
  selected = false;
  visible = true;
  touched = false;

  constructor(gridDots: DisplacementGridDots, x: number, y: number) {
    this.gridDots = gridDots;
    makeAutoObservable(this);
  }

  get svgCoordinates(): PointArrayNotation {
    const res = applyToPoint(this.gridDots.grid.coordinates.modelToSvgMatrix, [
      this.x,
      this.y,
    ] as PointArrayNotation);
    return res;
  }

  setVisible(v: boolean) {
    this.visible = v;
  }

  setTouched(v: boolean) {
    this.touched = v;
  }

  setY(y: number) {
    this.y = y;
  }

  setX(x: number) {
    this.x = x;
  }

  get panResponder() {
    const panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onPanResponderStart: (evt, gestureState) => {
        this.gridDots.grid.mouseHelper.startDragging(this);
      },
      onPanResponderMove: (evt, gestureState) => {
        const dc = { x: gestureState.dx, y: gestureState.dy };
        //console.info(JSON.stringify(dc));
        this.gridDots.grid.mouseHelper.move(this, gestureState);
      },
      onPanResponderRelease: (evt, gestureState) => {
        this.gridDots.grid.mouseHelper.finishDragging(this);
      },
    });
    return panResponder;
  }
}
