import { makeAutoObservable } from "mobx";
import { now } from "mobx-utils";
import {
  PanResponder,
  PanResponderGestureState,
  ViewStyle,
} from "react-native";
import { nanoid } from "nanoid";
import { LinearNodesShared } from "./LinearNodesModel";

function easeInOutQuad(t: number, b: number, c: number, d: number) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
  return (-c / 2) * (--t * (t - 2) - 1) + b;
}

function easeInOutElastic(t: number, b: number, c: number, d: number) {
  var s = 1.70158;
  var p = 0;
  var a = c;
  if (t == 0) return b;
  if ((t /= d / 2) == 2) return b + c;
  if (!p) p = d * (0.3 * 1.5);
  if (a < Math.abs(c)) {
    a = c;
    var s = p / 4;
  } else var s = (p / (2 * Math.PI)) * Math.asin(c / a);
  if (t < 1)
    return (
      -0.5 *
        (a *
          Math.pow(2, 10 * (t -= 1)) *
          Math.sin(((t * d - s) * (2 * Math.PI)) / p)) +
      b
    );
  return (
    a *
      Math.pow(2, -10 * (t -= 1)) *
      Math.sin(((t * d - s) * (2 * Math.PI)) / p) *
      0.5 +
    c +
    b
  );
}

export class LinearNodeShared {
  id = nanoid();
  owner;
  linearNodes: ILinearNodes;
  _name?: string = undefined;
  _x?: number = undefined;
  movable = true;
  movingShift: [number, number] | null = null;
  animateFromIndex: null | number = null;
  selected = false;
  moved = false;

  constructor(owner: ILinearNode, linearNodes: ILinearNodes) {
    this.owner = owner;
    this.linearNodes = linearNodes;
    makeAutoObservable(this);
  }

  setAnimateFromIndex(v: number | null) {
    this.animateFromIndex = v;
  }

  setMoved(v: boolean) {
    this.moved = v;
  }

  get animating() {
    return (
      this.animateFromIndex !== null &&
      this.index !== this.animateFromIndex &&
      this.linearNodes.shared.animationStartedAt !== null
    );
  }

  setSelected(v: boolean) {
    this.selected = v;
  }

  get animatingX() {
    if (
      this.animateFromIndex !== null &&
      this.linearNodes.shared.animationStartedAt !== null
    ) {
      const x1 = this.getXForItemWithIndex(this.animateFromIndex);
      const x2 = this.getXForItemWithIndex(this.index);
      const t = now("frame") - this.linearNodes.shared.animationStartedAt;
      const dur = this.linearNodes.shared.animationDuration;
      const v = easeInOutQuad(t, x1, x2 - x1, dur);
      return v;
    }
  }

  click(gesture: PanResponderGestureState) {
    console.info("click");
    if (this.selected) {
      this.selected = false;
    } else {
      this.linearNodes.shared.clearSelection();
      this.selected = true;
    }
  }

  get name() {
    return typeof this._name === "string" ? this._name : this.id;
  }

  setName(n?: string) {
    this._name = n;
  }

  setMovingShift(v: [number, number] | null) {
    this.movingShift = v;
  }

  get dragging() {
    return !!this.movingShift;
  }

  get movedBox() {
    return [this.xx, this.yy, this.width, this.height];
  }

  get staticBox() {
    return [this.x, this.y, this.width, this.height];
  }

  setX(v: number) {
    this._x = v;
  }

  get xx() {
    return this.movingShift ? this.movingShift[0] + this.x : this.x;
  }

  get yy() {
    return this.movingShift ? this.movingShift[1] + this.y : this.y;
  }

  setMovable(v: boolean) {
    this.movable = v;
  }

  get width() {
    return this.linearNodes.shared.nodeWidth;
  }
  get height() {
    return this.linearNodes.shared.nodeHeight;
  }

  get index() {
    return this.linearNodes.shared.nodes.indexOf(this.owner);
  }

  get y() {
    return this.linearNodes.shared.height / 2 - this.height / 2;
  }

  getXForItemWithIndex(i: number) {
    return (
      this.linearNodes.shared.startMovableNodes +
      i * (this.width + this.linearNodes.shared.spacing)
    );
  }

  get x() {
    if (typeof this._x == "number") {
      return this._x;
    } else {
      return this.getXForItemWithIndex(this.index);
    }
  }

  get panResponder() {
    console.info("PR create");
    return PanResponder.create({
      onStartShouldSetPanResponderCapture: (_e, gesture) => false,
      onMoveShouldSetPanResponderCapture: (_e, gesture) => false,
      onStartShouldSetPanResponder: (evt, gesture) => {
        console.info(evt.nativeEvent.target);
        let res = true;
        //@ts-ignore
        if (evt.nativeEvent.target instanceof HTMLInputElement) {
          res = false;
        }
        console.info(
          "onStartShouldSetPanResponder",
          res,
          evt.nativeEvent.target
        );
        return res;
      },
      onPanResponderGrant: (e, gesture) => {
        this.setMoved(false);
        console.info(`onPanResponderGrant`);
      },
      onPanResponderMove: (e, gesture) => {
        console.info(`onPanResponderMove.moved=${this.moved}`);
        if (this.moved) {
          this.setMovingShift([gesture.dx, gesture.dy]);
        } else {
          const q = gesture.dx * gesture.dx + gesture.dy * gesture.dy;
          if (q > 5) {
            this.setMoved(true);
            this.setMovingShift([gesture.dx, gesture.dy]);
          }
        }
      },
      onPanResponderRelease: (e, gesture) => {
        console.info("moved", this.moved);
        if (this.moved) {
          console.info("onPanResponderRelease.drop");
          this.linearNodes.shared.drop();
        } else {
          console.info("onPanResponderRelease.click");
          this.click(gesture);
        }
        this.setMovingShift(null);
      },
    });
  }
}

export interface ILinearNode {
  readonly shared: LinearNodeShared;
}

export interface ILinearNodes {
  readonly shared: LinearNodesShared;
}

type Props = {
  style: ViewStyle;
  data: ILinearNodes;
};

export class DefaultLinearNode implements ILinearNode {
  shared: LinearNodeShared;

  constructor(linearNodes: ILinearNodes) {
    this.shared = new LinearNodeShared(this, linearNodes);
    makeAutoObservable(this);
  }
}
