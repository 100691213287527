import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { GridPropertiesPanelModel } from "../../../models/GridPropertiesPanel/GridPropertiesPanel";
import { Rect } from "../../../utils/Rect";
import { PanelWithTitle } from "../PanelWithTitle/PanelWithTitle";
import { GridPanelBody } from "./GridPanelBody";

type Props = {
  node: DefaultLutNode;
};

export const GridPanel = observer((props: Props) => {
  const { node } = props;
  const [model, setModel] = useState<GridPropertiesPanelModel | null>(null);
  useEffect(() => {
    setModel(new GridPropertiesPanelModel(node));
  }, []);
  if (model) {
    //const l = model.layout;
    return (
      <PanelWithTitle
        node={node}
        renderBody={function (where: Rect) {
          return <GridPanelBody model={model} />;
        }}
        renderToolbar={function (where: Rect) {
          return false;
        }}
      />
    );
  } else {
    return <View />;
  }
});
