import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { Rect } from "../../../utils/Rect";
import { PanelBody } from "./PanelBody";
import { MenuItems, PanelToolbar } from "./PanelToolbar";

type Props = {
  node: DefaultLutNode;
  renderBody: (where: Rect) => any;
  renderToolbar?: (where: Rect) => any;
  menuItems?: MenuItems;
};

export const PanelWithTitle = observer((props: Props) => {
  const { node, renderBody, renderToolbar, menuItems } = props;
  const r = node.lutCreator.layout.nodePropertiesRect;
  return (
    <View
      style={{
        ...r.layoutObject,
        overflow: "hidden",
      }}
    >
      <PanelBody node={node} renderBody={renderBody} />
      <PanelToolbar
        node={node}
        renderToolbar={renderToolbar}
        menuItems={menuItems}
      />
    </View>
  );
});
