import Checkbox from "expo-checkbox";
import { range } from "lodash";
import { observer } from "mobx-react";
import React, { FC, ReactChild, useState } from "react";
import { TouchableHighlight, View, Text, LayoutRectangle } from "react-native";
import { setSamplerParameters } from "twgl.js";
import { useTheme } from "../../utils/theme/ThemesProvider";

type TabCheckboxProps = {
  checked: boolean;
  setChecked: (v: boolean) => void;
};

const borderColor = "#252525";
const backgroundColor = "#3a3a3a";
const checkboxBorderColor1 = "#424344";
const checkboxBorderColor2 = "#262525";
const checkedRectBackgroundColor = "#fffffe";
const height = 40;
const checkboxWidth = 18;
const checkboxHeight = 30;

export const TabCheckbox: FC<TabCheckboxProps> = observer((props) => {
  const { setChecked, checked } = props;
  const {
    tabsTheme: { style: ss },
  } = useTheme();
  return (
    <Checkbox
      style={{
        margin: ss.textMargin,
        width: ss.checkboxWidth,
        height: ss.checkboxWidth,
      }}
      value={checked}
      onValueChange={setChecked}
    />
  );
  /*return (
    <TouchableHighlight onPress={setEnabled}>
      <View
        style={{
          position: "absolute",
          left: 2,
          top: (height - checkboxHeight) / 2,
          width: checkboxWidth,
          height: checkboxHeight,
          borderWidth: 2,
          borderColor: checkboxBorderColor1,
          borderStyle: "solid",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: checkboxWidth - 4,
            height: checkboxHeight - 4,
            borderWidth: 2,
            borderColor: checkboxBorderColor2,
            borderStyle: "solid",
            backgroundColor: backgroundColor,
          }}
        >
          <View
            style={{
              position: "absolute",
              left: 2,
              top: checked ? 0 : (checkboxHeight - 8 - (checkboxWidth + 8)),
              width: checkboxWidth - 8,
              height: checkboxWidth - 8,
              borderWidth: 1,
              borderColor: checkedRectBackgroundColor,
              borderStyle: "solid",
              backgroundColor: checked ? checkedRectBackgroundColor : "silver",
            }}
          ></View>
        </View>
      </View>
    </TouchableHighlight>
  );*/
});

type TabProps = {
  enabled: boolean;
  label: string;
  id: string;
  selected: boolean;
  hasCheckbox: boolean;
  setEnabled: (v: boolean) => void;
  setSelected: () => void;
  width: number;
};

export const Tab: FC<TabProps> = observer((props) => {
  const {
    enabled,
    label,
    selected,
    setSelected,
    hasCheckbox,
    setEnabled,
    width,
  } = props;
  const {
    tabsTheme: { style: ss },
  } = useTheme();
  const borc = ss.borders;
  return (
    <TouchableHighlight onPress={setSelected}>
      <View
        style={{
          //width: width,
          //height: height,
          //borderColor: ss.inactiveTabBackground,
          borderWidth: 1,
          height: ss.tabHeight - 2,
          backgroundColor: selected
            ? ss.tabBackground
            : ss.inactiveTabBackground,
          borderColor: "#ffffff00",
          borderStyle: "solid",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: ss.tabPadding,
          ...(selected
            ? {
                borderLeftColor: borc,
                borderRightColor: borc,
                borderTopColor: borc,
                borderBottomColor: ss.tabBackground,
              }
            : {}),
        }}
      >
        {hasCheckbox && (
          <TabCheckbox checked={enabled} setChecked={setEnabled} />
        )}

        <Text
          style={{
            //position: "absolute",
            //left: textLeft,
            //top: 4,
            //width: width - textLeft - 2,
            margin: ss.textMargin,
            //fontWeight: selected ? "bold" : "normal",
            fontWeight: "bold",
            color: selected ? "white" : "silver",
          }}
        >
          {label}
        </Text>
      </View>
    </TouchableHighlight>
  );
});

type TabPanelProps = {
  tabCount: number;
  renderTab: (index: number, tabWidth: number) => ReactChild;
};

export const TabPanel: FC<TabPanelProps> = observer((props) => {
  const { tabsTheme: tt } = useTheme();
  const [rect, setRect] = useState<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 100,
    height: tt.style.tabHeight,
  });
  const { tabCount, renderTab } = props;
  const tabWidth = rect.width / tabCount;
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 5,
        backgroundColor: tt.style.inactiveTabBackground,
      }}
      onLayout={(evt) => setRect(evt.nativeEvent.layout)}
    >
      <>{range(0, tabCount).map((i: number) => renderTab(i, tabWidth))}</>
    </View>
  );
});
