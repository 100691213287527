import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { FC, useEffect, useRef, useState } from "react";
import { View } from "react-native";
import Svg, { Circle, G, Line, Path, Rect as SvgRect } from "react-native-svg";
import { applyToPoint } from "transformation-matrix";
import { DotPointM } from "../../../LutEngineModel/LutEngineModel";
import { CurveModel } from "../../../models/CurvesPropertiesPanel/CurveModel";
import { useTheme } from "../../../utils/theme/ThemesProvider";

type Props = {
  model: CurveModel;
  dot: Instance<typeof DotPointM>;
};
export const CurveDot: FC<Props> = observer((props) => {
  const { model, dot: p } = props;
  const {
    tabsTheme: { style: ts },
  } = useTheme();
  const isPicked = model.pickedDot === p;
  const isOver = model.overDot === p;
  const isCurrent = model.currentDot === p;
  const ap = (p: PointArrayNotation) => applyToPoint(model.matrixDocToSvg, p);
  const pp = ap([p.x, p.y]);

  const outlineStyle = isPicked
    ? {}
    : {
        strokeDasharray: 5,
        animation: "dash 0.5s linear 0s infinite",
      };
  return (
    <>
      {isCurrent && (
        <Line
          x1={pp[0]}
          x2={pp[0]}
          y1={0}
          y2={model.layout.curveCanvasRectInSvg.height}
          stroke={ts.inactiveText}
          strokeDasharray={3}
          strokeWidth={1}
        />
      )}
      <G key={p.key} transform={`translate(${pp[0]}, ${pp[1]})`}>
        {(isOver || isPicked) && (
          <Circle
            //@ts-ignore
            style={outlineStyle as any}
            r={9}
            cx={0}
            cy={0}
            stroke={model.info.color}
          />
        )}
        <Circle
          r={model.isSelected ? 5 : 2}
          cx={0}
          cy={0}
          fill={model.info.color}
        />
      </G>
      <style>{` @keyframes dash { to { stroke-dashoffset: 10; } } `}</style>
    </>
  );
});
