import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { drawPreview } from "./drawPreview";
import { LutCreator } from "../../models/LutCreator";
import { View } from "react-native";

type Props = { lutCreator: LutCreator };

export const PreviewCanvas = observer((props: Props) => {
  const {
    lutCreator,
    lutCreator: { preview },
  } = props;
  const width = preview.scaledImageSize.width;
  const height = preview.scaledImageSize.height;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef1 = useRef<HTMLCanvasElement>(null);
  const gpuImage = lutCreator.lutCanvas;
  useEffect(() => {
    if (canvasRef1.current && lutCreator.lutCanvas) {
      canvasRef1.current.width = width;
      canvasRef1.current.height = height;
      drawPreview(canvasRef1.current, lutCreator);
    }
    //if (canvasRef1.current) {
    //canvasRef1.current.width = width;
    //canvasRef1.current.height = height;
    //const ctx = canvasRef.current?.getContext("2d");
    //const { width: w, height: h } = gpuImage;
    //console.info({ w, h });
    //ctx?.drawImage(app.resultLut.gpuImage, 0, 0);
    //}
  }, [
    canvasRef.current,
    canvasRef1.current,
    gpuImage,
    lutCreator.drawIndex,
    width,
    height,
  ]);
  /*<img
        src={preview.opened!.image!.src}
        style={{
          position: "absolute",
          width: preview.scaledImageSize.width,
          height: preview.scaledImageSize.height,
        }}
      />*/
  return (
    <View
      style={{
        position: "relative",
        width,
        height,
      }}
    >
      {false && (
        <canvas
          ref={canvasRef}
          style={{
            border: "0px solid blue",
            position: "absolute",
            width,
            height,
          }}
        ></canvas>
      )}
      <canvas
        ref={canvasRef1}
        key={`${width}__${height}`}
        style={{
          border: "0px solid red",
          position: "absolute",
          width,
          height,
        }}
      ></canvas>
    </View>
  );
});
