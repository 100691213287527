import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { View, Text } from "react-native";
import { LutCreator } from "../../models/LutCreator";
import { PreviewState } from "../../models/LutPreview/LutPreview";
import { useTheme } from "../../utils/theme/ThemesProvider";
import { PreviewCanvas } from "../PreviewCanvas/PreviewCanvas";
import { DocumentJsonTree } from "../PreviewToolbar/DocumentJsonTree";

type Props = {
  lutCreator: LutCreator;
};

export const PreviewPanel = observer((props: Props) => {
  const { lutCreator } = props;
  const p = lutCreator.layout.previewPanelRect;
  const theme = useTheme();
  const {width, height} = p;
  useEffect(() => {
    lutCreator.preview.setSize(p.width - 10, p.height - 10);
  }, [p.width, p.height]);
  const renderPageAccordingToState = () => {
    switch (lutCreator.preview.state) {
      case PreviewState.DocumentJsonTree:
        return <DocumentJsonTree style={{width, height}} lutCreator={lutCreator} />;
      default:
        return <PreviewCanvas lutCreator={lutCreator} />;
    }
  };
  return (
    <View
      style={{
        borderColor: theme.colors["panel.border"],
        backgroundColor: theme.colors["panel.background"],
        ...p.layoutObject,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {renderPageAccordingToState()}
    </View>
  );
});
