import { makeAutoObservable } from "mobx";

type TRect = { x: number; y: number; width: number; height: number };
type TRectArr = [number, number, number, number];

export class Rect {
  x = 0;
  y = 0;
  width = 100;
  height = 100;

  constructor(
    args: TRect | TRectArr | number,
    y?: number,
    width?: number,
    height?: number
  ) {
    if (Array.isArray(args)) {
      this.x = args[0];
      this.y = args[1];
      this.width = args[2];
      this.height = args[3];
    } else if (typeof args === "number") {
      this.x = args;
      this.y = typeof y == "number" ? y : 0;
      this.width = typeof width == "number" ? width : 0;
      this.height = typeof height == "number" ? height : 0;
    } else {
      this.x = args.x;
      this.y = args.y;
      this.width = args.width;
      this.height = args.height;
    }
    makeAutoObservable(this);
  }

  getExpanded(x: number, y?: number): Rect {
    if (typeof y == "undefined") {
      y = x;
    }
    const res = new Rect(
      this.x - x,
      this.y - y,
      this.width + 2 * x,
      this.height + 2 * y
    );
    return res;
  }

  get x1() {
    return this.x;
  }
  get x2() {
    return this.x + this.width;
  }
  get y1() {
    return this.y;
  }
  get y2() {
    return this.y + this.height;
  }

  get layoutObject(): {
    position: "absolute";
    left: number;
    top: number;
    width: number;
    height: number;
  } {
    return {
      position: "absolute",
      left: this.x,
      top: this.y,
      width: this.width,
      height: this.height,
    };
  }

  get arr(): TRectArr {
    return [this.x, this.y, this.width, this.height];
  }

  get obj(): TRect {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
    };
  }
}
