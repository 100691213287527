import { flow, IObservableArray, makeAutoObservable, observable } from "mobx";
import { SampleImage } from "./SampleImage";
import { LutCreator } from "../LutCreator";
import testimage from "../../../web/samples/testimage.jpeg";
import { StillImage } from "./StillImage";

export enum PreviewState {
  Picture = "Sample Picture",
  //ShaderText = "shader",
  //DocumentText = "document",
  DocumentJsonTree = "Json Tree",
}

function selectFile(
  contentType: string,
  multiple: boolean
): Promise<File | Array<File>> {
  return new Promise((resolve) => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = multiple;
    input.accept = contentType;

    input.onchange = () => {
      if (input.files) {
        let files = Array.from(input.files);
        if (multiple) resolve(files);
        else resolve(files[0]);
      }
    };

    input.click();
  });
}

function readFileIntoImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const image = new Image();
        image.title = file.name;
        image.src = reader.result as string;
        image.onload = () => {
          resolve(image);
        };
        image.onerror = (err) => reject(err);
      },
      false
    );
    reader.readAsDataURL(file);
  });
}
export class LutPreview {
  showShader: boolean = false;
  app;
  width = 100;
  height = 100;
  opened: SampleImage | StillImage | null = null;
  samples: IObservableArray<SampleImage | StillImage> = observable.array([
    new SampleImage(this, testimage),
    //new SampleImage(this, "/samples/flower.jpg"),
  ]);
  state: PreviewState = PreviewState.Picture;

  constructor(app: LutCreator) {
    this.app = app;
    makeAutoObservable(this);
    this.open(this.samples[0]);
  }

  setState(k: PreviewState) {
    this.state = k;
  }

  setSize(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  open(item: SampleImage | StillImage): void {
    this.opened = item;
  }

  get scaledImageSize() {
    if (
      this.opened &&
      this.opened.image &&
      this.opened.image.width &&
      this.opened.image.height
    ) {
      const P = 4;
      const scale = Math.min(
        (this.width - 2 * P - 50) / this.opened.image.width,
        (this.height - 2 * P) / this.opened.image.height
      );
      const width = this.opened.image.width * scale;
      const height = this.opened.image.height * scale;
      const res = { width, height };
      console.info({ width, height });
      return res;
    } else {
      return { width: 100, height: 100 };
    }
  }

  close(): void {
    this.opened = null;
  }

  switchShowShader(): void {
    this.showShader = !this.showShader;
  }

  openFile = flow(function* openFile(this: LutPreview) {
    try {
      const file: File = yield selectFile("image/*", false);
      const image: HTMLImageElement = yield readFileIntoImage(file);
      const preview = new StillImage(image, file.name);
      this.samples.push(preview);
      this.open(preview);
    } catch (ee) {
      console.error(ee);
    }
  });
}
