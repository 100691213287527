import Slider from "@react-native-community/slider";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { DefaultLutNode } from "../../../models/DefaultLutNode";
import { useTheme } from "../../../utils/theme/ThemesProvider";

type Props = { p: any; node: DefaultLutNode };

export const NumberProperty = observer((props: Props) => {
  const { p } = props;
  const { node } = props;
  const r = node.lutCreator.layout.nodePropertiesRect;
  const theme = useTheme();
  const [defValue, setDefValue] = useState(0);
  useEffect(() => {
    setDefValue(p.value);
  }, []);
  return (
    <View>
      <Text
        style={{
          fontSize: theme.fontSizes[3],
          color: theme.colors["editor.foreground"],
        }}
      >
        {p.json.title}
        def=[{defValue}]
        {p.max}
      </Text>
      <Slider
        style={{ width: r.width, height: 40 }}
        minimumValue={p.min}
        maximumValue={p.max}
        value={defValue}
        onValueChange={(num) => {
          node.lutCreator.setNumberValue(node.shared.index, p.json.id, num);
        }}
        minimumTrackTintColor="#FFFFFF"
        maximumTrackTintColor="#000000"
      />
    </View>
  );
});
