import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Text } from "react-native";
import { ILinearNode } from "../models/LinearNodeModel";
import { useTheme } from "../../../utils/theme/ThemesProvider";

type Props = {
  node: ILinearNode;
};

export const NodeContent = observer((props: Props) => {
  const { node } = props;
  const theme = useTheme();

  return (
    <Text
      numberOfLines={1}
      style={{
        fontSize: theme.fontSizes[3],
        color: node.shared.movable ? theme.colors["editor.foreground"] : theme.colors["editorGhostText.foreground"],
      }}
    >
      {node.shared.name}
    </Text>
  );
});
