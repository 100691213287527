import { makeAutoObservable } from "mobx";
import { DefaultLutNode } from "../DefaultLutNode";
import { CurvesPropertiesPanelLayout } from "./CurvesPropertiesPanelLayout";
import { CurveStack } from "./CurveStack";

export enum CurvesSubpanels {
  LEFT = "left",
  RIGHT = "right",
}

export enum CurvePanelState {
  CURVE = "Curve",
  PROPS = "Properties",
  JSON = "Json",
}

export class CurvesPropertiesPanelModel {
  layout = new CurvesPropertiesPanelLayout(this);
  state: CurvePanelState = CurvePanelState.CURVE;
  node;
  rightStack = new CurveStack(this, [
    {
      color: "white",
      title: "Master",
      id: 903886569,
      enabledId: 2508071083,
    },
    {
      color: "red",
      title: "Red",
      enabledId: 911255163,
      id: 1015414887,
    },
    {
      color: "green",
      title: "Green",
      id: 832598765,
    },
    {
      color: "blue",
      title: "Blue",
      id: 3011788902,
    },
  ]);

  constructor(node: DefaultLutNode) {
    this.node = node;
    makeAutoObservable(this);
  }

  setState(state: CurvePanelState) {
    this.state = state;
  }

  get lutCreator() {
    return this.node.lutCreator;
  }
}
