import { observer } from "mobx-react";
import React from "react";
import { LutCreator } from "../../models/LutCreator";
import { LinearNodes } from "../../modules/LinearNodes/components/LinearNodes";
import { ILinearNode } from "../../modules/LinearNodes/models/LinearNodeModel";
import { LutNodeContent } from "./LutNodeContent";

type Props = { lutCreator: LutCreator };

export const NodesPanel = observer((props: Props) => {
  const { lutCreator } = props;
  const rect = lutCreator.layout.nodesPanelRect;

  const renderNodeContent = (node: ILinearNode) => (
    <LutNodeContent node={node} />
  );

  return (
    <LinearNodes
      style={{
        ...rect.layoutObject,
      }}
      customRenderer={() => true}
      renderNodeContent={renderNodeContent}
      data={lutCreator}
    />
  );
});
