import * as twgl from "twgl.js";
import { LutCreator } from "../../models/LutCreator";

export const GSGL_PREFIX = `#define GLSLIFY 1
precision mediump float;

uniform sampler2D uTexture;
uniform sampler2D uLookup;
uniform float stop;
uniform float dim;
varying vec2 vUv;

`;

const vs = `#define GLSLIFY 1

precision mediump float;

attribute vec2 position;
varying vec2 vUv;

void main() {
  vUv = (position + vec2(1.0)) / 2.0;
  vUv.y = 1.0 - vUv.y;
  gl_Position = vec4(position, 1.0, 1.0);
}
`;

const sizes = [4, 9, 16, 32, 64, 128];

const fs =
  GSGL_PREFIX +
  `


vec4 lookup_1_0(in vec4 textureColor, in sampler2D lookupTable) {
    #ifndef LUT_NO_CLAMP
        textureColor = clamp(textureColor, 0.0, 1.0);
    #endif

    //mediump float dim = 64.0;
    mediump float v8 = sqrt(dim);
    mediump float v63 = dim-1.0;
    mediump float v0125 = 1.0/v8;
    mediump float v512 = dim * v8;

    mediump float blueColor = textureColor.b * v63;

    mediump vec2 quad1;
    quad1.y = floor(floor(blueColor) / v8);
    quad1.x = floor(blueColor) - (quad1.y * v8);

    mediump vec2 quad2;
    quad2.y = floor(ceil(blueColor) / v8);
    quad2.x = ceil(blueColor) - (quad2.y * v8);

    highp vec2 texPos1;
    texPos1.x = (quad1.x * v0125) + 0.5/v512 + ((v0125 - 1.0/v512) * textureColor.r);
    texPos1.y = (quad1.y * v0125) + 0.5/v512 + ((v0125 - 1.0/v512) * textureColor.g);

    #ifdef LUT_FLIP_Y
        texPos1.y = 1.0-texPos1.y;
    #endif

    highp vec2 texPos2;
    texPos2.x = (quad2.x * v0125) + 0.5/v512 + ((v0125 - 1.0/v512) * textureColor.r);
    texPos2.y = (quad2.y * v0125) + 0.5/v512 + ((v0125 - 1.0/v512) * textureColor.g);

    #ifdef LUT_FLIP_Y
        texPos2.y = 1.0-texPos2.y;
    #endif

    lowp vec4 newColor1 = texture2D(lookupTable, texPos1);
    lowp vec4 newColor2 = texture2D(lookupTable, texPos2);

    lowp vec4 newColor = mix(newColor1, newColor2, fract(blueColor));
    return newColor;
}

void main() {
	vec4 color = texture2D(uTexture, vUv);

	//if (vUv.x > stop)
		gl_FragColor = lookup_1_0(color, uLookup);
	//else 
		//gl_FragColor = color;
}

`;
export function drawPreview(canvas: HTMLCanvasElement, lutCreator: LutCreator) {
  const gl = canvas.getContext("webgl2");
  //console.info('drawPreview')
  if (!gl) {
    console.error("Error getting gl context");
    return;
  }

  //const shader = ""; ///TODO
  const programInfo = twgl.createProgramInfo(gl, [vs, fs]);
  const bufferInfo = twgl.primitives.createXYQuadBufferInfo(gl, 2);
  gl.useProgram(programInfo.program);
  twgl.setBuffersAndAttributes(gl, programInfo, bufferInfo);
  const lut = lutCreator.lutCanvas;
  if (lutCreator.preview.opened!.image && lut) {
    const imgTexture = twgl.createTexture(gl, {
      src: lutCreator.preview.opened!.image!,
      min: gl.LINEAR,
      mag: gl.LINEAR,
    });
    const lutTexture = twgl.createTexture(gl, {
      src: lut,
      min: gl.LINEAR,
      mag: gl.LINEAR,
    });
    twgl.setUniformsAndBindTextures(programInfo, {
      uTexture: imgTexture,
      uLookup: lutTexture,
      stop: 100,
      dim: lutCreator.engine.lutSize,
    });

    // calls gl.drawArrays or gl.drawElements
    twgl.drawBufferInfo(gl, bufferInfo);
  }
}

/*
function createLUTTexture(
  gl: WebGL2RenderingContext,
  img: TexImageSource,
  filter = gl.NEAREST,
  size = 16
) {
  const tex = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, tex);
  gl.texStorage2D(gl.TEXTURE_2D, 1, gl.RGBA8, size, size);
  // grab  slices
  for (let z = 0; z < size; ++z) {
    gl.pixelStorei(gl.UNPACK_SKIP_PIXELS, z * size);
    gl.pixelStorei(gl.UNPACK_ROW_LENGTH, img.width);
    gl.pixelStorei(gl.UNPACK_SKIP_PIXELS, z * size);
    gl.pixelStorei(gl.UNPACK_ROW_LENGTH, img.width);
    gl.texSubImage3D(
      gl.TEXTURE_3D,
      0, // mip level
      0, // x
      0, // y
      z, // z
      size, // width,
      size, // height,
      1, // depth
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      img
    );
  }
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, filter);
  return tex;
}
*/
